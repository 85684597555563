import React, { createContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CookiesProvider, useCookies } from "react-cookie";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "./components/Register/Register";
import Login from "./components/Login/Login";
import './App.scss';
import HomePage from './components/HomePage';
import ViewOnlyMode from './components/ViewOnlyMode';
import InaugurationPage from './components/InaugurationPage';
import AdminPanel from './components/AdminPanel/AdminPanel';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import { io } from 'socket.io-client';
import { InventorySocketIP, clientSocketIP } from './components/MyConfiguration';
import MonitorDrones from './components/MonitorDrones/MonitorDrones';
import DashboardPage from './Pages/DashboardPage/DashboardPage';
import ViewAllDrones from './components/ViewAllDrones/ViewAllDrones';
import AirspacePage from './Pages/AirspacePage/AirspacePage';




function App() {
  const themesAvailable = {
    light: {
      "--primaryColor": "white",
      "--secondaryColor": "#F2F2F2",
      "--primaryTextColor": "#08030A",
      "--secondaryTextColor": "#e2e2e2"
    },
    dark: {
      "--primaryColor": "#000",
      "--secondaryColor": "#0B0B0B",
      "--primaryTextColor": "white",
      "--secondaryTextColor": "#e2e2e2"
    }
  };
  const [nameLogo, setNameLogo] = useState('TE-logoName.svg')
  const [EagleLogo, SetEagleLogo] = useState('TechEagle_Logo.svg')
  const [userTokenCookie, setUserTokenCookie, removeUserTokenCookie] = useCookies(["token"]);
  const MovingRingRef = useRef();
  const [themeColor, SetThemeColor] = useState("dark")
  // const ThemeContext = createContext({ themeColor: themeColor, SetThemeColor: SetThemeColor })
  const [theme, setTheme] = useState(1)
  // const [inventorySocket, setInventorySocket] = useState(io(InventorySocketIP, {
  //   autoConnect: false,
  //   transports: ["websocket"],
  //   auth: {
  //     token: userTokenCookie.token
  //   }
  // }))
  // const [droneSocket, setDroneSocket] = useState(io(clientSocketIP, {
  //   autoConnect: false,
  //   transports: ["websocket"],
  //   auth: {
  //     token: userTokenCookie.token
  //   }
  // }))

  // useEffect(() => {
  //   setInventorySocket(io(InventorySocketIP, {
  //     autoConnect: false,
  //     transports: ["websocket"],
  //     auth: {
  //       token: userTokenCookie.token
  //     }
  //   }))
  //   setDroneSocket(io(clientSocketIP, {
  //     autoConnect: false,
  //     transports: ["websocket"],
  //     auth: {
  //       token: userTokenCookie.token
  //     }
  //   }))
  // }, [userTokenCookie.token])

  const dimentionMultiplier = 100;
  document.body.style.fontSize = ((window.innerHeight / 1080) * 16).toString() + "px";
  const [SizeMultiplier, setSizeMultiplier] = useState({
    "--widthmultiplier": (parseInt(window.innerWidth * dimentionMultiplier / 1920) / dimentionMultiplier),
    "--heightmultiplier": (parseInt(window.innerHeight * dimentionMultiplier / 1080) / dimentionMultiplier),
    height: window.innerHeight.toString() + "px",
    width: window.innerWidth.toString() + "px"
  });





  useLayoutEffect(() => {
    function handleResize() {
      let calFontHeight = window.innerHeight.toString() + "px";
      let calFontWidth = window.innerWidth.toString() + "px";
      let calMulHeight = (parseInt(window.innerHeight * dimentionMultiplier / 1080) / dimentionMultiplier);
      let calmulWidth = (parseInt(window.innerWidth * dimentionMultiplier / 1920) / dimentionMultiplier);
      setSizeMultiplier({ "--widthmultiplier": calmulWidth, "--heightmultiplier": calMulHeight, height: calFontHeight, width: calFontWidth });
      // console.log(calmulWidth, calMulHeight);
      // console.log(calFontHeight, calFontWidth);
      document.body.style.fontSize = ((window.innerHeight / 1080) * 16).toString() + "px";
      // document.body.style.width = window.innerWidth.toString() + "px";
      // document.body.style.height = window.innerHeight.toString() + "px";
      // console.log(SizeMultiplier)
    }
    window.addEventListener("resize", handleResize);
    return () => { window.removeEventListener("resize", handleResize) };
  }, []);

  useEffect(() => {

    if (theme === -1) {
      SetThemeColor(themesAvailable.light);
      setNameLogo("TE-logoName.svg");
      SetEagleLogo("TechEagle_Logo.svg")
    }
    else {
      SetThemeColor(themesAvailable.dark);
      setNameLogo("TE-logoNameDark.svg");
      SetEagleLogo("TechEagle_LogoDark.svg")
    }
    // console.log(themeColor)

  }, [theme])

  return (
    <div className='app-page-container' style={{
      ...SizeMultiplier,
      "--primaryColor": themeColor["--primaryColor"],
      "--secondaryColor": themeColor["--secondaryColor"],
      "--primaryTextColor": themeColor["--primaryTextColor"],
      "--secondaryTextColor": themeColor["--secondaryTextColor"],
      // width:"calc(1920px * var(--widthmultiplier))",
      // height:"calc(1080px * var(--heightmultiplier))"
    }}>
      <CookiesProvider>
        <BrowserRouter>
          <Routes>
          <Route path="/dashboard" element={<DashboardPage  themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} />} />
            <Route path="/monitor" element={<HomePage  themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} />} />
              <Route path="/monitor-all-drones" element={<MonitorDrones  themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} />} />
            <Route path="/view-all-drones" element={<ViewAllDrones  themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} />} />
            <Route path="/view-flight" element={<ViewOnlyMode themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} />} />
            <Route path="/adminpanel"  element={<AdminPanel  themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} />} />
            <Route path="/" element={<Login themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} />} />
            <Route path="/register" element={<Register themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} />} />
            <Route path="/forgot-password" element={<ForgotPassword themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} />} />
              <Route path="/airspace" element={<AirspacePage themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} />} />
          </Routes>
        </BrowserRouter>
      </CookiesProvider>
      <div className='app-page-container__themeSwitch'
        onClick={(e) => {
          setTheme((prev) => { return prev * (-1) })
          MovingRingRef.current.style.transform = `translateX(calc(${-10 * theme}px * var(--widthmultiplier)))`
          // console.log(e.currentTarget.style.tra)
        }}
      >
        <div className='app-page-container__themeSwitch__circle'
          ref={MovingRingRef}
        >
        </div>
      </div>
    </div>

  );
}

export default App;
