import React, { useEffect, useRef, useState } from "react";
import "./HubLocationDetails.scss";

function HubLocationDetails({ setDetailsPopUp, hubDetails }) {
  const modalRef = useRef();

  const closeModal = e => {
    if (modalRef.current === e.target) {
        setDetailsPopUp();
    }
  };
  // console.log(hubDetails)


  // console.log(hubDetails)
  return (
    <div onClick={closeModal} ref={modalRef} className="HubRelatedNodesDetailsPopup-modalBackground">
      <div className="HubRelatedNodesDetailsPopup-modalContainer" >
        <div className="HubRelatedNodesDetailsPopup-modalContainer__heading">Nodes Data</div>
        <div className="HubRelatedNodesDetailsPopup-modalContainer__dataContainer">
        {hubDetails.map((item, i) => {
          return <div className="HubRelatedNodesDetailsPopup-modalContainer__item" key={i+1}>
            <div className="HubRelatedNodesDetailsPopup-modalContainer__item__title">Node {i+1}</div>
            :
            <div className="HubRelatedNodesDetailsPopup-modalContainer__item__value"> {item.location_name}</div>
            </div>
        })}
        </div>

        <div className="HubRelatedNodesDetailsPopup-modalContainer__OkayBtn" onClick={() => { modalRef.current.click() }}>OK</div>
      </div>
    </div>
  );
}

export default HubLocationDetails;
