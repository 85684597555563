import React, { useEffect, useRef } from 'react'
import "./VideoPlayer.scss"


const VideoPlayer = (videoSRC,connectWatcher) => {

    const videoRef =useRef()
    useEffect(() => {
        videoRef.current.srcObject = videoSRC;
    }, [videoRef])
    
    return (
        <div className='VideoPlayer-container'
            onClick={() => {
                connectWatcher()
            }}>
            <video className='VideoPlayer-container__player' autoPlay muted ref={videoRef} width={"100%"} height={"100%"}></video>
        </div>
    )
}

export default VideoPlayer;