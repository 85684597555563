import { usePagination } from "./PaginationFunction"
import React from "react";
import "./Pagination.scss"

type paginationProps = {
    totalItems?: any
    itemsPerPage?: any
    onPageChange?: any
    currentPage?: any
}


const PaginationComponent = (props: paginationProps) => {

    const currentPage = props.currentPage
    const totalCount = props.totalItems
    const pageSize = props.itemsPerPage
    const siblingCount = 1
    const paginationRange:any = usePagination({totalCount, pageSize, siblingCount , currentPage})
    const totalPages = Math.ceil(props.totalItems/ props.itemsPerPage);
    console.log("paginationRange", paginationRange)
    console.log("paginationProps", props)

    if (props.currentPage === 0 || paginationRange.length < 2) {
        return null
    }
    
    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            props.onPageChange(page);
        }
    };

    // let lastPage = paginationRange[paginationRange.length - 1]

    return (
        <div className='Pagination_Component'>
            <div className='Pagination_Component__leftArrow' onClick={() => handlePageChange(props.currentPage - 1)}>
                <svg className={`${props.currentPage === 1 ? "Pagination_Component__leftArrow__BtnInActive" : "Pagination_Component__leftArrow__BtnActive"}`} width="16" height="13" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5.94777 7.08957C6.74816 8.07465 8.25185 8.07465 9.05223 7.08957L14 1" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                <div className={`${props.currentPage === 1 ? "Pagination_Component__leftArrow__InActiveText" : "Pagination_Component__leftArrow__ActiveText"}`}>Previous</div>
            </div>
            <div className='Pagination_Component__pages'>
                {paginationRange.map((num: any, i:number) => {
                    if (num === "DOTS") {
                        return <div className='Pagination_Component__pages__Dots'>. . .</div>
                    }
                    return <div className={`${props.currentPage === num ? "Pagination_Component__pages__Active" : "Pagination_Component__pages__InActive"}`} key={i+1}>{num}</div>
                })}
            </div>
            <div className='Pagination_Component__rightArrow' onClick={() => handlePageChange(props.currentPage + 1)}>
                <div className={`${props.currentPage === totalPages ? "Pagination_Component__rightArrow__InActiveText" : "Pagination_Component__rightArrow__ActiveText"}`}>Next</div>
                <svg className={`${props.currentPage === totalPages ? "Pagination_Component__rightArrow__BtnInActive" : "Pagination_Component__rightArrow__BtnActive"}`} width="16" height="13" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5.94777 7.08957C6.74816 8.07465 8.25185 8.07465 9.05223 7.08957L14 1" stroke-width="1.5" stroke-linecap="round" />
                </svg>
            </div>
        </div>
    )
}

export default PaginationComponent