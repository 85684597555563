import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./OrderDetailsTab.scss"
import dayjs from "dayjs";
import axios from "axios";
import { InventoryBackEndIP, backEndIP } from "../MyConfiguration";
import { useSelector } from "react-redux";
import { selectHubNodeLocations } from "../../ReduxStore/HubNodeLocationsSlice";
import CancellationOrderTemplateContent from "../CancellationOrderTemplateContent/CancellationOrderTemplateContent";
import CustomPopUpTemplete from "../CustomPopUpTemplete/CustomPopUpTemplete";


const DroneDropDownBox = ({ item, options, selectedDrone, setSelectedDrone }) => {
    const InputRef = useRef();

    const handleInputChange = (e) => {
        setSelectedDrone(e.target.value)
    };

    return (<div className="OrderDetailsTab-dronedropDown-cont">
        <select className="OrderDetailsTab-dronedropDown-cont__input"
            style={{ outline: "none", border: "none" }}
            defaultValue={selectedDrone}
            onChange={handleInputChange}
            ref={InputRef}
        >
            <option key={"dummySelectedDrone"} value={""}>{item.label}</option>
            {options.map((op) => {
                return <option key={op.internal_id} value={op.internal_id}>{op.internal_id}</option>
            })
            }
        </select>
    </div>)
}

const OrderDetailsTab = (
    { item,
        setShowPopup,
        SetLoadingVisibility,
        userCookie,
        handleNotifyAlert,
        index, updateOrdersPage,
        allNewPanelLog
    }) => {
    const [availableDrones, setAvailableDrones] = useState([])
    // const [refresh, setRefresh] = useState(false)
    const currentOrderNo = item.order_no;
    const droneListContent = { label: "Select Drone", key: "drone_id", options: [], inurl: "/drone/get_all_drones" }
    //setCurrentItem(item)
    const [selectedDrone, setSelectedDrone] = useState("")
    const [cancellationPopup, setCancellationPopup] = useState(false)
    const [userData, setUserData] = useState({})
    // const locations = {
    //     "63932c7ab1cab628335ae969": "Jengjal",
    //     "63930f82865ec3abed90bc1b": "Mendipathar PHC",
    //     "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
    //     "639311e2865ec3abed90bc23": "Nongalbibra PHC",
    //     "6393126c865ec3abed90bc27": "Shallang PHC",
    //     "639312ff865ec3abed90bc2b": "Samanda PHC",
    //     "63bd5b3ccafee0c35772f8f1": "Office",
    // }
    const locations = useSelector(selectHubNodeLocations)
    // console.log(item)

    useEffect(() => {
        SetLoadingVisibility(true)
        axios({
            url: InventoryBackEndIP + `/order/getRespectedUser/${item.useremail}`,
            method: "GET",
            headers: {
                authorization: userCookie.token,
            },
        }).then((Respdata) => {
            SetLoadingVisibility(false)
            //console.log(data.data);
            //console.log(Respdata.data.data)
            setUserData(Respdata.data.data)
            //setUpdateSelf(prev => !prev)
        }).catch((err) => {
            //console.log(err);
            SetLoadingVisibility(false);
            handleNotifyAlert(
                "notify",
                `${err.response.data.message}`,
                "failed",
                () => { }
            );
        })
    }, []);
    // const handleProcessCancelOrder = (index) => {
    //     console.log('item',)
    //     SetLoadingVisibility(true)
    //     axios({
    //         url: InventoryBackEndIP + "/order/cancel_order",
    //         method: "PUT",
    //         headers: {
    //             authorization: userCookie.token,
    //         },
    //         data: {
    //             order_id: item._id,
    //             statusNumber: 9,
    //             statusName: "Canceled"
    //         }
    //     }).then((resp) => {
    //         console.log(resp.data.data)
    //         SetLoadingVisibility(false)
    //         handleNotifyAlert(
    //             "notify",
    //             `Order Cancelled successfully`,
    //             "success",
    //             () => { }
    //         );
    //         console.log(resp.data.data)
    //         SetLoadingVisibility(false)
    //     })
    //         .catch((err) => {
    //             console.log(err);
    //             SetLoadingVisibility(false);
    //             handleNotifyAlert(
    //                 "notify",
    //                 `${err.response.data.message}`,
    //                 "failed",
    //                 () => { }
    //             );
    //         });
    // }

    useEffect(() => {
        if (item.current_status.number === 3) {
            axios({
                url: backEndIP + "" + "/drone/get_flight_available_drones/" + item.order_no,
                method: "GET",
                headers: {
                    authorization: userCookie.token,
                },
                // data: { id: product._id , quantity:1 }
            })
                .then((resp) => {

                    // console.log(resp.data);
                    setAvailableDrones(resp.data.data);
                    droneListContent.options = resp.data.data

                })
                .catch((err) => {

                    if (err.response.data.message) window.alert(err.response.data.message);
                    else window.alert("some error occurered");
                    console.log(err);
                    setAvailableDrones([]);
                    droneListContent.options = []
                });

        }
    }, [])

    const handleCancel = () => {
        setShowPopup(false);
    }
    const handleOrderCancel = () => {
        //setShowPopup(false);
        //SetLoadingVisibility(false)
        setCancellationPopup(true)
        // handleNotifyAlert("alert", ` Are you sure! You want to Cancel Order No. ${item.order_no}?`, "failed", () => { handleProcessCancelOrder(index) })

    }


    const handleCreateFlightClick = () => {
        SetLoadingVisibility(true)
        axios({
            url: backEndIP + "/flight/new-flight-entry",
            headers: {
                authorization: userCookie.token,
            },
            method: "POST",
            data: {
                payload: item.total_weight,
                flight_type: "Order Flight",
                order_destination_location: item.destination_location,
                drone_id: selectedDrone,
                order_no: item.order_no,
                end_location: item.destination_location,
            }
        }).then(resp => {
            SetLoadingVisibility(false)
            // console.log(resp.data);

            updateOrdersPage()
            allNewPanelLog(`Flight entry created for order no ${currentOrderNo}`)
            handleNotifyAlert(
                "notify",
                resp.data.message,
                "success",
                () => {
                }
            );

        }).catch((respErr) => {
            SetLoadingVisibility(false)
            console.log(respErr.response.data)
            updateOrdersPage()
            handleNotifyAlert(
                "alert",
                respErr.response.data.message,
                "failed",
                () => { }
            );
        })
    }

    //userCookie,currentOrderNo,SetLoadingVisibility
    // console.log(item)
    return (
        <div className="OrderDetailContainer">
            {/* <div onClick={handleCancel} className="cancelBtn">X</div> */}
            <div className="OrderDetailContainer__heading">
                <div className="OrderDetailContainer__heading__h1">View Order Details</div>
            </div>
            <div className="OrderDetailContainer__Container">
                <div className="OrderDetailContainer__detailsContainer">
                    <div className='OrderDetailContainer__detailsContainer__Title'>
                        Order No: {currentOrderNo}
                    </div>
                    <div className='OrderDetailContainer__detailsContainer__row2'>
                        <div className='OrderDetailContainer__detailsContainer__row2__TotalItem'>Total Items : {item.cart.length}</div>
                        {item.flightId !== "" ?
                            item.current_status.number >= 3 && <div className='OrderDetailContainer__detailsContainer__row2__flightInput'>
                                <div>Flight Id : {item.flightId}</div>
                            </div> :
                            item.current_status.number === 3 && <div className='OrderDetailContainer__detailsContainer__row2__flightInput'>
                                <DroneDropDownBox item={droneListContent} options={availableDrones} selectedDrone={selectedDrone} setSelectedDrone={setSelectedDrone} />
                                <button onClick={handleCreateFlightClick}>Create Flight</button>
                            </div>}

                    </div>
                    <div className='OrderDetailContainer__detailsContainer__sub-Container'>
                        <div className='OrderDetailContainer__detailsContainer__sub-Container__title' >
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__title__Sr-No'>S.No</div>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__title__name'>Name</div>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__title__weight'>Weight</div>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__title__Volumetricweight'>V - Weight</div>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__title__quantity'>Qty</div>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__title__totalWeight'>Total Weight</div>
                        </div>
                        <div className='OrderDetailContainer__detailsContainer__sub-Container__dataContainer'>
                            {item.cart.map((item, i) => {

                                return (<div key={`${item.useremail} ${item.name} ${item.weight} `} className='OrderDetailContainer__detailsContainer__sub-Container__listDown'>
                                    <div className='OrderDetailContainer__detailsContainer__sub-Container__listDown__Sr-No'>{i + 1}</div>
                                    <div className='OrderDetailContainer__detailsContainer__sub-Container__listDown__name'>{item.name}</div>
                                    <div className='OrderDetailContainer__detailsContainer__sub-Container__listDown__weight'>{item.weight}</div>
                                    <div className='OrderDetailContainer__detailsContainer__sub-Container__listDown__Volumetricweight'>{item.v_weight}</div>
                                    <div className='OrderDetailContainer__detailsContainer__sub-Container__listDown__quantity'>{item.quantity}</div>
                                    <div className='OrderDetailContainer__detailsContainer__sub-Container__listDown__totalWeight'>{item.quantity * item.weight}</div>
                                </div>)
                            })}
                        </div>
                        <div className='OrderDetailContainer__detailsContainer__sub-Container__totalWeight'>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__totalWeight__title'>Cart Weight</div>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__totalWeight__value'>{item.total_weight}g</div>
                            {/* <div className='ListDownItems-container__quantity'>Total Price</div> */}
                            {/* <div className='ListDownItems-container__total-price'>{calculateTotalPrice()}Rs</div> */}
                        </div>
                    </div>
                </div>
                <div className="OrderDetailContainer__Right">
                    <div className="OrderDetailContainer__dataContainer">
                        Current Status : {item.current_status.name}
                    </div>
                    <div className="OrderDetailContainer__content">
                        <div className='OrderDetailContainer__content__customerDataContainer'>
                            <div className='OrderDetailContainer__content__customerDataContainer__label'>Customer Details:</div>
                            <div className='OrderDetailContainer__content__customerDataContainer__value'>
                                <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer'>
                                    <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer__label'>
                                        Name : </div>{userData.userName}</div>
                                <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer'>
                                    <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer__label'>
                                        Email : </div>{userData.useremail}</div>
                                <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer'>
                                    <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer__label'>
                                        Phone No : </div>{userData.phone_no}</div>
                            </div></div>
                        <div className="OrderDetailContainer__content__user">
                            <div className="OrderDetailContainer__content__user__label">Customer Email</div>
                            <div className="OrderDetailContainer__content__user__colon"> : </div>
                            <div className="OrderDetailContainer__content__user__value">{item.useremail}</div></div>
                        <div className="OrderDetailContainer__content__type">
                            <div className="OrderDetailContainer__content__type__label">Delivery Type</div>
                            <div className="OrderDetailContainer__content__type__colon"> : </div>
                            <div className="OrderDetailContainer__content__type__value">{item.delivery_type}</div></div>
                        <div className="OrderDetailContainer__content__startLoc">
                            <div className="OrderDetailContainer__content__startLoc__label">Source Location</div>
                            <div className="OrderDetailContainer__content__startLoc__colon"> : </div>
                            <div className="OrderDetailContainer__content__startLoc__value">{locations[item.source_location].name}</div></div>
                        <div className="OrderDetailContainer__content__endLoc">
                            <div className="OrderDetailContainer__content__endLoc__label">Delivery Location</div>
                            <div className="OrderDetailContainer__content__endLoc__colon"> : </div>
                            <div className="OrderDetailContainer__content__endLoc__value">{locations[item.destination_location].name}</div></div>
                        <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Order Placed Date</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(item.placed).format("YYYY-MM-DD HH:mm").toString()}</div> </div>
                        {item.approved === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Approved Date </div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(item.approved).format("YYYY-MM-DD HH:mm").toString()}</div></div>}
                        {item.packed === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Packed Date</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(item.packed).format("YYYY-MM-DD HH:mm").toString()}</div></div>}
                        {item.sent_from_hub === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Sent From Hub On</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(item.sent_from_hub).format("YYYY-MM-DD HH:mm").toString()}</div></div>}
                        {item.intransit === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">In Transit</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(item.intransit).format("YYYY-MM-DD HH:mm").toString()}</div></div>}
                        {item.recieved_at_local_station === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Received At Local Station</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(item.recieved_at_local_station).format("YYYY-MM-DD HH:mm").toString()}</div></div>}
                        {item.sent_out_delivery === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Out For Delivery</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(item.sent_out_delivery).format("YYYY-MM-DD HH:mm").toString()}</div></div>}
                        {item.delivered === null ? <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Expected Delivery Date</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(item.delivery_date).format("YYYY-MM-DD HH:mm").toString()}</div></div> : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Delivered On</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(item.delivered).format("YYYY-MM-DD HH:mm").toString()}</div></div>}
                        {item.cancelled === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Cancelled Date</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(item.cancelled).format("YYYY-MM-DD HH:mm").toString()}</div></div>}
                        {item.cancellationReason &&
                            <div className="OrderDetailContainer__content__date">
                                <div className="OrderDetailContainer__content__date__label">Cancellation Reason</div>
                                <div className="OrderDetailContainer__content__date__colon"> : </div>
                                <div className="OrderDetailContainer__content__date__value">{item.cancellationReason}</div></div>}
                    </div>
                    {item.current_status.number < 4 &&
                        <div className="OrderDetailContainer__button">
                            <button className="OrderDetailContainer__button__orderCancel" onClick={handleOrderCancel}>Cancel order
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div className="OrderDetailContainer__Options">
                <div onClick={handleCancel} id="OkayBtn">OK</div>
            </div>
            {cancellationPopup &&
                <CustomPopUpTemplete setOpenModal={setCancellationPopup} pageTitle={"Order Cancel Form"} content={
                    <CancellationOrderTemplateContent setCancellationPopup={setCancellationPopup} setShowPopup={setShowPopup}
                        orderData={item}
                        userCookie={userCookie}
                        SetLoadingVisibility={SetLoadingVisibility}
                        handleNotifyAlert={handleNotifyAlert}
                        index={index} />}
                />}

        </div>
    )
}
export default OrderDetailsTab;