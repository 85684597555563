import React, { useEffect, useState } from 'react';
import "./AllUsersTabel.scss"
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';
import UserDetailsPopUp from '../UserDetailsPopUp/UserDetailsPopUp';
import dayjs from 'dayjs';


const AllUsersTabel = ({ userCookie, setSelectedTab, SetLoadingVisibility, handleNotifyAlert }) => {

    const [allUsers, setAllUsers] = useState([])
    const [hubLocation, setHubLocation] = useState([])
    const [myLocation, setMyLocation] = useState([])
    const [detailsPopUp, setDetailsPopUp] = useState(false)
    const [selectedUser, setSelecteduser] = useState()
    const [updateSelf, setUpdateSelf] = useState(false)
    const [filterBy, setFilterBy] = useState("ALL")
    const [allUsersCopy, setAllUsersCopy] = useState([])
    useEffect(() => {
        axios({
            url: backEndIP + "/admin/getUsers",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then((resp) => {
            //console.log(resp.data)
            setAllUsers(resp.data.data)
            setAllUsersCopy(resp.data.data)
        }).catch((err) => {
            console.log(err);
        })

    }, [updateSelf])

    useEffect(() => {
        SetLoadingVisibility(true)
        axios({
            url: backEndIP + `/hub/gethub`,
            method: "GET",
            headers: {
                authorization: userCookie.token,
            }
        }).then((resp) => {
            SetLoadingVisibility(false)
            //console.log(resp.data)
            setHubLocation(resp.data.data)
        }).catch((err) => {
            //console.log(err)
            SetLoadingVisibility(false);
            handleNotifyAlert(
                "notify",
                `${err.response.data.message}`,
                "failed",
                () => { }
            );
        })
    }, []);
    useEffect(() => {
        SetLoadingVisibility(true)
        axios({
            url: backEndIP + `/location/get_allLocation`,
            method: "GET",
            headers: {
                authorization: userCookie.token,
            }
        }).then((resp) => {
            SetLoadingVisibility(false)
            //console.log("setMyLocation",resp.data)
            setMyLocation(resp.data.data)
        }).catch((err) => {
            //console.log(err)
            SetLoadingVisibility(false);
            handleNotifyAlert(
                "notify",
                `${err.response.data.message}`,
                "failed",
                () => { }
            );
        })
    }, []);

    const handleFilterChange = (e, i) => {
        // console.log(e.currentTarget.innerHTML)
        let verifiedData = []
        //console.log(i)
        setFilterBy(i)
        if (i === "ALL") {
            verifiedData = [...allUsersCopy]

        } else if (i === "Approved") {
            verifiedData = []
            allUsersCopy.forEach((item) => {
                if (item.isApproved === true ) {
                    verifiedData.push(item)
                }
            })
        } else if (i === "Not Approved") {
            verifiedData = []
            allUsersCopy.forEach((item) => {
                if (item.isApproved === false) {
                    verifiedData.push(item)
                }
            })
        }
        else if (i === "Granted") {
            verifiedData = []
            allUsersCopy.forEach((item) => {
                if (item.permission === "Granted") {
                    verifiedData.push(item)
                }
            })
        }
        else if (i === "Not Granted") {
            verifiedData = []
            allUsersCopy.forEach((item) => {
                if (item.permission === "Not Granted") {
                    verifiedData.push(item)
                }
            })
        }
        //console.log(verifiedData)
        setAllUsers(verifiedData)
    }

    // console.log("filtered", filterBy)

    return (
        <div className='MainAllUsersTab'>
            <div className='AllUsersTabContent-container'>
                <div className='AllUsersTabContent-container__filter'>
                    <div className='AllUsersTabContent-container__filter__title'>Filter by : </div>
                    <div className='AllUsersTabContent-container__filter__options'>
                        <button className={filterBy === "ALL" ? 'AllUsersTabContent-container__filter__options__selected-item' : 'AllUsersTabContent-container__filter__options__item'}
                            onClick={(e) => { handleFilterChange(e, "ALL") }}>ALL</button>
                        <button className={filterBy === "Approved" ? 'AllUsersTabContent-container__filter__options__selected-item' : 'AllUsersTabContent-container__filter__options__item'}
                            onClick={(e) => { handleFilterChange(e, "Approved") }}>Approved</button>
                        <button className={filterBy === "Not Approved" ? 'AllUsersTabContent-container__filter__options__selected-item' : 'AllUsersTabContent-container__filter__options__item'}
                            onClick={(e) => { handleFilterChange(e, "Not Approved") }}>Not Approved</button>
                        <button className={filterBy === "Granted" ? 'AllUsersTabContent-container__filter__options__selected-item' : 'AllUsersTabContent-container__filter__options__item'}
                            onClick={(e) => { handleFilterChange(e, "Granted") }}>Permited</button>
                        <button className={filterBy === "Not Granted" ? 'AllUsersTabContent-container__filter__options__selected-item' : 'AllUsersTabContent-container__filter__options__item'}
                            onClick={(e) => { handleFilterChange(e, "Not Granted") }}>Not Permited</button>
                    </div>
                </div>
                <div className='AllUsersTabContent-datacontainer' >
                    <div className='AllUsersTabContent-container__index'>
                        <div className='AllUsersTabContent-container__index__SNo AllUsersTabContent-container__index__term'>
                            S.No
                        </div>
                        <div className='AllUsersTabContent-container__index__userName AllUsersTabContent-container__index__term'>
                            User Name
                        </div>
                        <div className='AllUsersTabContent-container__index__userEmail AllUsersTabContent-container__index__term' >
                            Email
                        </div>
                        <div className='AllUsersTabContent-container__index__Phone_no AllUsersTabContent-container__index__term'>
                            Phone No
                        </div>
                        <div className='AllUsersTabContent-container__index__date_birth AllUsersTabContent-container__index__term'>
                            Date Of Birth
                        </div>
                        <div className='AllUsersTabContent-container__index__gender AllUsersTabContent-container__index__term' >
                            Gender
                        </div>
                        <div className='AllUsersTabContent-container__index__permission AllUsersTabContent-container__index__term'>
                            Permission
                        </div>
                        <div className='AllUsersTabContent-container__index__hub_location AllUsersTabContent-container__index__term'>
                            Related Hub
                        </div>
                        <div className='AllUsersTabContent-container__index__delivery_loc AllUsersTabContent-container__index__term'>
                            Node Name
                        </div>
                        <div className='AllUsersTabContent-container__index__approval AllUsersTabContent-container__index__term' >
                            Approval
                        </div>
                        <div className='AllUsersTabContent-container__index__getDetails AllUsersTabContent-container__index__term' >
                            Manager User
                        </div>
                    </div>
                    {allUsers.map((user, i) => {
                        return <div key={i+1} className='AllUsersTabContent-datacontainer__row'>
                            <div className='AllUsersTabContent-datacontainer__row__SNo AllUsersTabContent-datacontainer__row__term'>
                                {i + 1}
                            </div>
                            <div className='AllUsersTabContent-datacontainer__row__userName AllUsersTabContent-datacontainer__row__term'>
                                {user.userName}
                            </div>
                            <div className='AllUsersTabContent-datacontainer__row__userEmail AllUsersTabContent-datacontainer__row__term'>
                                {user.useremail}
                            </div>
                            <div className='AllUsersTabContent-datacontainer__row__Phone_no AllUsersTabContent-datacontainer__row__term'>
                                {user.phone_no===""||user.phone_no===null?"NA":user.phone_no}
                            </div>
                            <div className='AllUsersTabContent-datacontainer__row__date_birth AllUsersTabContent-datacontainer__row__term'>
                                {user.date_birth===""||user.date_birth===null?"NA":dayjs(user.date_birth).format("DD-MM-YYYY")}
                            </div>
                            <div className='AllUsersTabContent-datacontainer__row__gender AllUsersTabContent-datacontainer__row__term'>
                                {user.gender}
                            </div>
                            <div className='AllUsersTabContent-datacontainer__row__permission AllUsersTabContent-datacontainer__row__term'>
                                {user.permission}
                            </div>
                            <div className='AllUsersTabContent-datacontainer__row__hub_location AllUsersTabContent-datacontainer__row__term'>
                                {hubLocation.map((location, i) => {
                                    if (location._id === user.location) {
                                        return <div key={i+1}>{`${location.location_name}(${location.tag_name})`}</div>
                                    }
                                })}
                            </div>
                            <div className='AllUsersTabContent-datacontainer__row__delivery_loc AllUsersTabContent-datacontainer__row__term'>
                                {myLocation.map((location, i) => {
                                    if (location._id === user.delivery_location) {
                                        return <div key={i+1}>{`${location.location_name}(${location.tag_name})`}</div>
                                    }
                                })}
                            </div>
                            <div className='AllUsersTabContent-datacontainer__row__approval AllUsersTabContent-datacontainer__row__term' >
                                {user.isApproved === true ? "Approved" : "Not Approved"}
                            </div>
                            <div className='AllUsersTabContent-datacontainer__row__getDetails AllUsersTabContent-datacontainer__row__term' >
                                <button className='AllUsersTabContent-datacontainer__row__getDetails__options' onClick={() => { setDetailsPopUp(true); setSelecteduser(user) }}>Manage User</button>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            {detailsPopUp && <UserDetailsPopUp
                setOpenModal={() => { setSelectedTab("All Users"); setDetailsPopUp(false) }}
                userDetails={selectedUser} 
                nodesData={myLocation} 
                userCookie={userCookie} 
                SetLoadingVisibility={SetLoadingVisibility} 
                handleNotifyAlert={handleNotifyAlert} 
                setUpdateSelf={setUpdateSelf} />}
        </div>
    )
}

export default AllUsersTabel