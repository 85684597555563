import React, { useEffect, useState } from 'react';
import EmployeeDeployPopup from '../EmployeeDeployPopup/EmployeeDeployPopup';
import "./EmployeesTabContent.scss"
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';
import AddNewEmployeeForm from '../AddNewEmployeeForm/AddNewEmployeeForm';


const EmployeesTabContent = ({ 
    employees, 
    setEmployees,
    adminLocation, 
    adminDesignation, 
    allLocations, 
    refreshDeployLocations, 
    setRefreshDeployLocations, 
    adminAuthLocType,
    userCookie, 
    SetLoadingVisibility, 
    handleNotifyAlert }) => {


    const [employeeDeployVisibility, setEmployeeDeployVisibility] = useState(false);
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [addEmployeePopup, setAddEmployeePopup] = useState(false)



    const locations = {
        "country": "countryNames",
        "zone": "zoneNames",
        "state": "stateNames",
        "hub": "hubNames",
    }

    useEffect(() => {
        axios({
            url: backEndIP + "/admin/employee",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then((employeesResp) => {
            //console.log(employeesResp.data)
            setEmployees(employeesResp.data.data);
        }).catch((err) => {
            console.log(err);
        })

    }, [addEmployeePopup])
    const handleFilterChange = (e) => {
    }

    // console.log("All Location", allLocations)

    return (
        <div className='EmployeesTabContent-container'>
            {adminDesignation === "System User"?<div className='EmployeesTabContent-container__filter'>
                <button className='EmployeesTabContent-container__filter__addButton' onClick={()=>{setAddEmployeePopup(true)}}>Add Employee</button>
            </div>: null}
            <div className='EmployeesTabContent-datacontainer'>
                <div className='EmployeesTabContent-container__index'>
                    <div className='EmployeesTabContent-container__index__Employee EmployeesTabContent-container__index__term'>
                        Employee
                    </div>
                    <div className='EmployeesTabContent-container__index__active EmployeesTabContent-container__index__term'>
                        Account Status
                    </div>
                    <div className='EmployeesTabContent-container__index__Designation EmployeesTabContent-container__index__term'>
                        Designation
                    </div>
                    <div className='EmployeesTabContent-container__index__Country EmployeesTabContent-container__index__term'>
                        Country
                    </div>
                    <div className='EmployeesTabContent-container__index__Zone EmployeesTabContent-container__index__term'>
                        Zone
                    </div>
                    <div className='EmployeesTabContent-container__index__State EmployeesTabContent-container__index__term'>
                        State
                    </div>
                    <div className='EmployeesTabContent-container__index__Hub EmployeesTabContent-container__index__term'>
                        Physical Location
                    </div>
                    <div className='EmployeesTabContent-container__index__authLoc EmployeesTabContent-container__index__term'>
                        Authorized Location
                    </div>
                    <div className='EmployeesTabContent-container__index__Change-Location EmployeesTabContent-container__index__term'>
                        Actions
                    </div>
                </div>
                {employees?.map((employee) => {
                    //console.log(employee)
                    return <div className='EmployeesTabContent-datacontainer__row' key={employee._id}>
                        <div className='EmployeesTabContent-datacontainer__row__Employee EmployeesTabContent-datacontainer__row__term'>
                            {employee.userName}
                        </div>
                        <div className='EmployeesTabContent-datacontainer__row__active EmployeesTabContent-datacontainer__row__term'>
                            {employee.active ? "Active" : "Deactivated"}
                        </div>
                        <div className='EmployeesTabContent-datacontainer__row__Designation EmployeesTabContent-datacontainer__row__term'>
                            {employee.designation}
                        </div>
                        <div className='EmployeesTabContent-datacontainer__row__Country EmployeesTabContent-datacontainer__row__term'>
                            {employee.country && allLocations.countryNames[employee.country] ? allLocations.countryNames[employee.country] : ""}
                        </div>
                        <div className='EmployeesTabContent-datacontainer__row__Zone EmployeesTabContent-datacontainer__row__term'>
                            {employee.zone && allLocations.zonesNames[employee.zone] ? allLocations.zonesNames[employee.zone] : ""}
                        </div>
                        <div className='EmployeesTabContent-datacontainer__row__State EmployeesTabContent-datacontainer__row__term'>
                            {employee.state && allLocations.stateNames[employee.state] ? allLocations.stateNames[employee.state] : ""}
                        </div>
                        <div className='EmployeesTabContent-datacontainer__row__Hub EmployeesTabContent-datacontainer__row__term'>
                            {employee.location && allLocations.hubNames[employee.location] ? allLocations.hubNames[employee.location] : ""}
                        </div>
                        <div className='EmployeesTabContent-datacontainer__row__authLoc EmployeesTabContent-datacontainer__row__term'>
                        {employee.authorized_location && allLocations.allNames[employee.authorized_location] ? allLocations.allNames[employee.authorized_location] : ""}
                            {/* {employee.authorized_location && allLocations[locations[employee.authorized_location_type]][employee.authorized_location] ? allLocations[locations[employee.authorized_location_type]][employee.authorized_location] : ""} */}
                        </div>
                        <div className='EmployeesTabContent-datacontainer__row__Change-Location EmployeesTabContent-datacontainer__row__term'>
                            <button className='EmployeesTabContent-datacontainer__row__Change-Location__getDtilbtn EmployeesTabContent-datacontainer__row__term'
                             onClick={() => { setEmployeeDetails(employee); setEmployeeDeployVisibility(true) }}>Get Details</button>
                        </div>
                    </div>
                })}
            </div>
            {employeeDeployVisibility &&
                <EmployeeDeployPopup
                    setEmployees={setEmployees}
                    adminDesignation={adminDesignation}
                    setOpenModal={setEmployeeDeployVisibility}
                    employeeDetails={employeeDetails}
                    allLocations={allLocations}
                    userCookie={userCookie}
                    SetLoadingVisibility={SetLoadingVisibility} 
                    handleNotifyAlert={handleNotifyAlert}
                    adminLocation={adminLocation}
                    adminAuthLocType={adminAuthLocType}
                />}
                  {addEmployeePopup &&
                <AddNewEmployeeForm
                    setOpenModal={setAddEmployeePopup}
                    setEmployees={setEmployees}
                    employees={employees}
                    userCookie={userCookie}
                    SetLoadingVisibility={SetLoadingVisibility} 
                    handleNotifyAlert={handleNotifyAlert}
                />}
        </div>
    )
}

export default EmployeesTabContent