import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import FlightDetailsPopup from "../FlightDetailsPopup/FlightDetailsPopup";
import { backEndIP } from "../MyConfiguration";
import "./CreateFlightTabContent.scss";



const InputDropDownBox = ({ item, setInfoToSend, setFlightType, userCookie }) => {
    const InputRef = useRef();

    const handleInputChange = () => {
        setInfoToSend((prev) => {
            let obj = { ...prev };
            obj[item.key] = InputRef.current.value;
            return obj;
        });
        setFlightType(InputRef.current.value);
    };
    useEffect(() => {
        setInfoToSend((prev) => {
            let obj = { ...prev };
            obj[item.key] = item.options[0];
            return obj;
        });
    }, [])


    return (<div className="tab-content-container__dropdown-row-cont">
        <div className="tab-content-container__dropdown-row-cont__label">{item.label}</div>
        <select className="tab-content-container__dropdown-row-cont__input"
            style={{ outline: "none", border: "none" }}
            onChange={handleInputChange}
            ref={InputRef}
        >
            {item.options?.map((op, i) => {
                return <option key={op + i} value={op}>{op}</option>
            })
            }
        </select>
    </div>)
}

const LocationDropDownBox = ({ item, options, setInfoToSend }) => {
    const InputRef = useRef();

    const handleInputChange = () => {
        setInfoToSend((prev) => {
            let obj = { ...prev };
            obj[item.key] = InputRef.current.value;
            return obj;
        });
    };
    useEffect(() => {
        setInfoToSend((prev) => {
            let obj = { ...prev };
            obj[item.key] = InputRef.current.value;
            return obj;
        });
    }, [options])

    // console.log("option", options)

    return (<div className="tab-content-container__dropdown-row-cont">
        <div className="tab-content-container__dropdown-row-cont__label">{item.label}</div>
        <select className="tab-content-container__dropdown-row-cont__input"
            style={{ outline: "none", border: "none" }}
            onChange={handleInputChange}
            ref={InputRef}
        >
            {options?.map((op) => {
                return <option key={op?._id} value={op?._id}>{op?.location_name}</option>
            })
            }
        </select>
    </div>)
}

const DroneDropDownBox = ({ item, options, setInfoToSend }) => {
    const InputRef = useRef();

    const handleInputChange = () => {
        setInfoToSend((prev) => {
            let obj = { ...prev };
            obj[item.key] = InputRef.current.value;
            return obj;
        });
    };
    useEffect(() => {
        setInfoToSend((prev) => {
            let obj = { ...prev };
            obj[item.key] = InputRef.current.value;
            return obj;
        });
    }, [options])

    return (<div className="tab-content-container__dropdown-row-cont">
        <div className="tab-content-container__dropdown-row-cont__label">{item.label}</div>
        <select className="tab-content-container__dropdown-row-cont__input"
            style={{ outline: "none", border: "none" }}
            onChange={handleInputChange}
            ref={InputRef}
        >
            {Array.isArray(options) && options?.map((op) => {
                return <option key={op.internal_id} value={op.internal_id}>{op.internal_id}</option>
            })
            }
        </select>
    </div>)
}



const InputFieldBox = ({ item, setInfoToSend, }) => {
    const InputRef = useRef()
    const handleInputChange = () => {
        setInfoToSend((prev) => {
            let obj = { ...prev };
            obj[item.key] = InputRef.current.value;
            return obj;
        });
    };

    return (
        <div className="tab-content-container__input-row-cont">
            <div className="tab-content-container__input-row-cont__label"
            >{item.label}</div>
            <div> : </div>
            <input ref={InputRef}
                className="tab-content-container__input-row-cont__input"
                type={item.type}
                onChange={handleInputChange}
            ></input>
        </div>
    );
};

const CreateFlightTabContent = ({ userCookie, setSelectedTab }) => {

    const content = {
        outurl: "/flight/flight_details",
        input: [
            // { label: "Add Mission File", key: "mission_file_name", type: "text" },
            { label: "Payload (grams)", key: "payload", type: "number" },
            { label: "Local Flight Id", key: "localFlightId", type: "string" },
            // { label: "Distance Covered (KM)", key: "distance_coverved", type: "number" },
            // { label: "Order No", key: "order_no", type: "number" }
        ],
        drop_down: [
            { label: "Type of Flight", key: "flight_type", options: ["Forward Flight","Return Flight","Test Flight"] },
            { label: "Start Location", key: "start_location", options: [], inurl: "/admin/get_hub_location" },
            { label: "End Location", key: "end_location", options: [], inurl: "/location/get_location" },
            { label: "Select Drone", key: "drone_id", options: [], inurl: "/drone/get_all_drones" },
        ],
        date: [{ label: "Start Time", key: "start_time" }, { label: "End Time", key: "end_time" }]

    }
    const [infoToSend, setInfoToSend] = useState({
        // ...content.input.map((item) => { return item.key }),
        // ...content.drop_down.map((item) => { return item.key })
    });
    const [flightType, setFlightType] = useState("Forward Flight");
    const [isLoaded, setIsLoaded] = useState(false);
    const [HubLocations, setHubLocations] = useState([]);
    const [locations, setLocations] = useState([]);
    const [drones, setDrones] = useState([]);
    const [flightDetailsVisibility, setFlightDetailsVisibility] = useState(false);
    const [flightDetails, setFlightDetails] = useState({});

    // const [startDateValue, setStartDateValue] = useState(dayjs());
    // const [endDateValue, setEndDateValue] = useState(dayjs());
    // const [timeTaken, setTimeTaken] = useState("");

    useEffect(() => {
        const getAllData = async () => {
            let hubs =[]
            await axios({
                url: backEndIP + "" + "/admin/get_hub_location",
                method: "GET",
                headers: {
                    authorization: userCookie.token,
                },
                // data: { id: product._id , quantity:1 }
            }).then((resp) => {
                // console.log("setHubLocations", resp.data.data)
                setHubLocations(resp.data.data)
                hubs = resp.data.data
                setInfoToSend((prev) => {
                    let obj = { ...prev };
                    obj[content.drop_down[1].key] = resp.data.data[0]._id;
                    return obj;
                });
            }).catch((err) => {
                if (err.response.data.message) window.alert(err.response.data.message);
                else window.alert("some error occurered");
                content.drop_down[1].options = []
            });

            await axios({
                url: backEndIP + "" + "/location/get_hub_nodes",
                method: "GET",
                headers: {
                    authorization: userCookie.token,
                },
                // data: { id: product._id , quantity:1 }
            }).then((resp) => {
                // console.log("get_location Resp", resp.data.data)
                setLocations(resp.data.data)
                setInfoToSend((prev) => {
                    let obj = { ...prev };
                    // console.log("HubLocations[0]?._id",hubs[0]?._id,resp.data.data[hubs[0]?._id][0]?._id)
                    if(hubs.length && resp.data.data[hubs[0]?._id]?.length )
                    obj[content.drop_down[2].key] = resp.data.data[hubs[0]?._id][0]?._id;
                    return obj;
                });
            }).catch((err) => {
                if (err.response.data.message) window.alert(err.response.data.message);
                else window.alert("some error occurered");
                content.drop_down[2].options = []
            });
            await axios({
                url: backEndIP + "" + "/drone/get_flight_available_drones",
                method: "GET",
                headers: {
                    authorization: userCookie.token,
                },
                // data: { id: product._id , quantity:1 }
            }).then((resp) => {
                //console.log(resp.data);
                setDrones(resp.data.data);
                content.drop_down[3].options = resp.data.data[hubs[0]?._id]

            }).catch((err) => {

                if (err.response.data.message) window.alert(err.response.data.message);
                else window.alert("some error occurered");
                //console.log(err);
                setDrones([]);
                content.drop_down[3].options = []
            });
        }
        getAllData()
    }, []);


    const handleCreateFlightClick = () => {
        //console.log(infoToSend);
        axios({
            url: backEndIP + "/flight/new-flight-test-return-entry",
            method: "POST",
            headers: {
                authorization: userCookie.token,
            },
            data: { ...infoToSend, order_destination_location: "0", order_no: 0 },
        }).then((resp) => {
            //console.log(resp.data);
            setFlightDetails(resp.data.data);
            setFlightDetailsVisibility(true);
        }).catch((err) => {
            if (err.response.data.message) window.alert(err.response.data.message);
            else window.alert("some error occurered");
            //console.log(err);
        });
    }
    // useEffect(() => {
    //     console.log("infoToSend",infoToSend)
    //     console.log(locations[infoToSend[content.drop_down[1].key]],drones[infoToSend[content.drop_down[1].key]])
    // }, [infoToSend])

    // console.log("Flight Type", flightType)
    return (
        <div className="tab-content-container">
            {content.input?.map((item) => {
                return <InputFieldBox key={item.key} item={item} setInfoToSend={setInfoToSend} userCookie={userCookie}></InputFieldBox>
            })}
            {/* {content.drop_down.map((item) => {
                return <InputDropDownBox key={item.key} item={item} setInfoToSend={setInfoToSend} userCookie={userCookie}></InputDropDownBox>
            })} */}
            <InputDropDownBox key={content.drop_down[0].label}
                setFlightType={setFlightType}
                item={content.drop_down[0]}
                setInfoToSend={setInfoToSend}
                userCookie={userCookie}></InputDropDownBox>
            <LocationDropDownBox key={content.drop_down[1].label}
                item={content.drop_down[1]}
                options={flightType === "Return Flight" ? locations[infoToSend[content.drop_down[2].key]] : HubLocations}
                setInfoToSend={setInfoToSend}
                userCookie={userCookie}></LocationDropDownBox>
            <LocationDropDownBox key={content.drop_down[2].label}
                item={content.drop_down[2]}
                options={flightType === "Return Flight" ? HubLocations : locations[infoToSend[content.drop_down[1].key]]}
                setInfoToSend={setInfoToSend}
                userCookie={userCookie}></LocationDropDownBox>
            <DroneDropDownBox key={content.drop_down[3].label}
                item={content.drop_down[3]}
                options={flightType === "Return Flight" ? drones[infoToSend[content.drop_down[2].key]] : drones[infoToSend[content.drop_down[1].key]]}
                setInfoToSend={setInfoToSend}
                userCookie={userCookie}></DroneDropDownBox>

            <button className="tab-content-container__create-flight-btn"
                onClick={handleCreateFlightClick}>
                Create Flight
            </button>

            {flightDetailsVisibility && <FlightDetailsPopup
                setOpenModal={() => { setSelectedTab("All Flights"); setFlightDetailsVisibility(false) }}
                flight={flightDetails} />}
        </div>
    );
};

export default CreateFlightTabContent;
