import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import OrderDetailsPopup from '../OrderDetailsPopup/OrderDetailsPopup';
import "./OrdersTabContent.scss"
import OrderDetailsTab from '../OrderDetailsTab/OrderDetailsTab';
import CustomPopUpTemplete from '../CustomPopUpTemplete/CustomPopUpTemplete';
import orderTabDataStore from './orderTabDataStore'
import { selectAllOrders, selectOrdersDuration, selectOrdersFilterBy, selectOrdersScrollTop, setAllOrdersData, setOrdersScrollTop, updateSpecificOrder } from './ordersDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectHubNodeLocations } from '../../ReduxStore/HubNodeLocationsSlice';

const updateStatusLabels ={
    placed:"Approve Order",
    approved:"Order Packed"
}

const OrdersTabContent = (
    {isConnected,
        registerfunc,
        allNewPanelLog,
        userCookie,
        socket,
        filterBy,
        setFilterBy,
        duration,
        setDuration,
        sortSetting,
        setSortSetting,
        handleNotifyAlert,
        SetLoadingVisibility
    }) => {
    const dispatch = useDispatch()
    const allOrders = useSelector(selectAllOrders)
    const OrdersFilterBy = useSelector(selectOrdersFilterBy)
    const OrdersDuration = useSelector(selectOrdersDuration)
    const OrdersScrollTop = useSelector(selectOrdersScrollTop)
    // console.log(allOrders)
    // console.log(allOrdersDataRedux,allOrderRedux,OrdersFilterByRedux,OrdersDurationRedux);
    // console.log("allOrders =", allOrders)
    // console.log("OrdersFilterByRedux =", OrdersFilterBy)
    // console.log("OrdersDurationRedux =", OrdersDuration)
    const locations = useSelector(selectHubNodeLocations)
    // console.log("locations =",locations)
    const setAllOrders = (data) => {
        dispatch(setAllOrdersData(data))
    }
    const updateOrderNo = (order) => {
        dispatch(updateSpecificOrder(order))
    }
    const saveScrollTop = (value) => {
        dispatch(setOrdersScrollTop(value))
    }



    // console.log("this orders tab is loader")
    const DurationOptions = {
        "Today": dayjs().set('hour', 0).set('minute', 0).set('second', 0),
        "Last 3 days": dayjs().subtract(2, "day").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 1 week": dayjs().subtract(1, "week").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 2 weeks": dayjs().subtract(2, "week").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 1 Month": dayjs().subtract(1, "month").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 3 Months": dayjs().subtract(3, "month").set('hour', 0).set('minute', 0).set('second', 0),
    }

    const [orderDetailsVisibility, setOrderDetailsVisibility] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});
    const [selectedOrder, setSelectedOrder] = useState(0);
    const [updateSelf, setUpdateSelf] = useState(false)
    const [searchedItems, setSearchItems] = useState([])
    const [searchInput, setSearchInput] = useState("")
    const [searchContentVisibility, setSearchContentVisibility] = useState(false)
    const [allOrdersLocal, setAllOrdersLocal] = useState([])
    const ordersListRef = useRef()

    // const locations = {
    //     "63932c7ab1cab628335ae969": "Jengjal",
    //     "63930f82865ec3abed90bc1b": "Mendipathar PHC",
    //     "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
    //     "639311e2865ec3abed90bc23": "Nongalbibra PHC",
    //     "6393126c865ec3abed90bc27": "Shallang PHC",
    //     "639312ff865ec3abed90bc2b": "Samanda PHC",
    // }


    const handleFilterChange = (e) => {
        // console.log(e.currentTarget.innerHTML)
        setFilterBy(e.currentTarget.innerHTML);
        // e.currentTarget.
    }

    const updateOrdersPageData = () => {
        // console.log("filterBy =",filterBy)
        socket.emit('client:get_all_orders', { filterBy: filterBy, duration: DurationOptions[duration.d] })
    }
    useEffect(()=>{
        if(isConnected){
            updateOrdersPageData()
        }
    },[isConnected])


    const sortRows = (Data, sortType = 0, key, sortBy) => {
        // console.log(sortType, key)
        const sortAsc = (a, b) => {
            // console.log(a[key], b[key], key)
            if (a[key] < b[key]) {
                return -1;
            }
            else if (a[key] > b[key]) {
                return 1;
            }
            else {
                return 0;
            }
        }
        const sortDsc = (a, b) => {
            // console.log(a[key], b[key], key)
            if (a[key] < b[key]) {
                return 1;
            }
            else if (a[key] > b[key]) {
                return -1;
            }
            else {
                return 0;
            }
        }

        const sortAscLoc = (a, b) => {
            console.log(locations[a[key]].name, locations[b[key]].name, key)
            if (locations[a[key]].name < locations[b[key]].name) {
                return -1;
            }
            else if (locations[a[key]].name > locations[b[key]].name) {
                return 1;
            }
            else {
                return 0;
            }
        }
        const sortDscLoc = (a, b) => {
            // console.log(locations[a[key]].name, locations[b[key]].name, key)
            if (locations[a[key]].name < locations[b[key]].name) {
                return 1;
            }
            else if (locations[a[key]].name > locations[b[key]].name) {
                return -1;
            }
            else {
                return 0;
            }
        }

        const sortNumberDsc = ((a, b) => {
            if (a.current_status.number < b.current_status.number) {
                return 1;
            }
            else if (a.current_status.number > b.current_status.number) {
                return -1;
            }
            else {
                return 0;
            }
        })
        const sortNumberAsc = ((a, b) => {
            if (a.current_status.number < b.current_status.number) {
                return -1;
            }
            else if (a.current_status.number > b.current_status.number) {
                return 1;
            }
            else {
                return 0;
            }
        })

        if (sortBy === "dsc") {
            // console.log("dcs")
            if (sortType === "loc")
                Data.sort(sortDscLoc);
            else if (sortType === "number")
                Data.sort(sortNumberDsc);
            else
                Data.sort(sortDsc);
            // console.log(dat)
        }
        else {
            if (sortType === "loc")
                Data.sort(sortAscLoc);
            else if (sortType === "number")
                Data.sort(sortNumberAsc);
            else
                Data.sort(sortAsc);
            // console.log(dat)
        }
        return Data
    }
    useEffect(() => {
        registerfunc(() => { saveScrollTop(ordersListRef.current.scrollTop); })
        // console.log("ran useeffect registerfunc")
    }, [ordersListRef.current])

        // const handelOrderReceived = () =>{
        //  setOrderReceived(true)
        //     const handelSearch = setTimeout(() => {
        //         setOrderReceived(false)
        //     }, 3000)
        //     return () => {
        //         // props.setLoading(true)
        //         clearTimeout(handelSearch)
        //     }
        // }


    // useEffect(() => {
    //     const onScrollEventHandler = (e) => {
    //         console.log("onScroll start")
    //         console.log("scrollHeight,scrollTop =", e.target.scrollHeight, e.target.scrollTop);
    //         console.log(e.target.scrollHeight - e.target.scrollTop)
    //         console.log("onScroll end")
    //     }
    //     if (!ordersListRef.current) { return }
    //     // ordersListRef.current.addEventListener("scroll", onScrollEventHandler)

    //     // return ordersListRef.current.removeEventListener("scroll", onScrollEventHandler)
    // }, [ordersListRef.current])
    useEffect(()=>{
        const tempOrders = [...allOrders]
        const data = sortRows(tempOrders, ...sortSetting)
        setAllOrdersLocal(tempOrders)
    },[allOrders,filterBy])

    useEffect(() => {
        const data = sortRows(!searchContentVisibility ? allOrdersLocal : searchedItems, ...sortSetting)
        setUpdateSelf(prev=>!prev)
        // const tempOrders = !searchContentVisibility ? [...allOrders] : [...searchedItems];
        // const sortedOrders = sortRows(tempOrders, ...sortSetting);
        // if (!searchContentVisibility) {
        //     setAllOrders(sortedOrders)
        // }
        // else {
        //     setSearchItems(tempOrders)
        // }
        // const data = sortRows(!searchContentVisibility ? allOrders : searchedItems, ...sortSetting)
        ordersListRef.current.scrollTop = OrdersScrollTop;//orderTabDataStore.scrollTop
    }, [sortSetting, searchContentVisibility,OrdersScrollTop,filterBy])

    useEffect(() => {

        const itemsSearched = [];
        allOrdersLocal.forEach((element, i) => {
            if (element.useremail.includes(searchInput)) {
                // console.log(element.useremail.indexOf(searchInput))
                itemsSearched.push(element)
            }
            if (i === allOrders.length - 1) {
                setSearchItems(itemsSearched)
                // console.log(itemsSearched)
            }
        });

    }, [searchInput, allOrdersLocal, filterBy])


    return (
        <div className='OrdersTabContent-container'>
            <div className='OrdersTabContent-container__filter'>
                <div className='OrdersTabContent-container__filter__title'>Filter by : </div>
                <div className='OrdersTabContent-container__filter__options'>
                    <button className={filterBy === "ALL" ? 'OrdersTabContent-container__filter__options__selected-item' : 'OrdersTabContent-container__filter__options__item'}
                        onClick={handleFilterChange}>ALL</button>
                    <button className={filterBy === "Open" ? 'OrdersTabContent-container__filter__options__selected-item' : 'OrdersTabContent-container__filter__options__item'}
                        onClick={handleFilterChange}>Open</button>
                    <button className={filterBy === "Delivered" ? 'OrdersTabContent-container__filter__options__selected-item' : 'OrdersTabContent-container__filter__options__item'}
                        onClick={handleFilterChange}>Delivered</button>
                    <button className={filterBy === "Cancelled" ? 'OrdersTabContent-container__filter__options__selected-item' : 'OrdersTabContent-container__filter__options__item'}
                        onClick={handleFilterChange}>Cancelled</button>
                </div>
                <div className='OrdersTabContent-container__filter__duration-title'>Duration :</div>
                <select className='OrdersTabContent-container__filter__duration-select'
                    defaultValue={duration.d}
                    onChange={(e) => {
                        // setDuration(DurationOptions[e.currentTarget.value]) 
                        duration.d = e.currentTarget.value
                        // orderTabDataStore.scrollTop = 0
                        saveScrollTop(0)
                        socket.emit('client:get_all_orders', { filterBy: filterBy, duration: DurationOptions[duration.d] })
                        // console.log("select =", e.currentTarget.value)
                    }}>
                    <option className='OrdersTabContent-container__filter__duration-select__option'>Last 3 days</option>
                    <option className='OrdersTabContent-container__filter__duration-select__option'>Today</option>
                    <option className='OrdersTabContent-container__filter__duration-select__option'>Last 1 week</option>
                    <option className='OrdersTabContent-container__filter__duration-select__option'>Last 2 weeks</option>
                    <option className='OrdersTabContent-container__filter__duration-select__option'>Last 1 Month</option>
                    <option className='OrdersTabContent-container__filter__duration-select__option'>Last 3 Months</option>
                </select>
                <div>Search : <input placeholder='user email' type='text' value={searchInput} onChange={(e) => {
                    setSearchInput(e.target.value); setSearchContentVisibility(true)
                }}></input><button onClick={() => { setSearchInput(""); setSearchContentVisibility(false) }}>X</button></div>
                {/* <div onClick={handelOrderReceived}>.</div> */}
            </div>

            <div className='OrdersTabContent-datacontainer'
                ref={ordersListRef}
            onScroll={(e) => {
                // console.log("onScroll start")
                // console.log("scrollHeight,scrollTop =", e.target.scrollHeight, e.target.scrollTop);
                // console.log(e.target.scrollHeight - e.target.scrollTop)
                // console.log("onScroll end")
                // if(ordersListRef.current)
                // orderTabDataStore.scrollTop = ordersListRef.current.scrollTop
                saveScrollTop(ordersListRef.current.scrollTop);
            }}
            >
                <div className='OrdersTabContent-container__index'
                >
                    <div className='OrdersTabContent-container__index__order-no OrdersTabContent-container__index__term'
                        onClick={() => {
                            if (sortSetting[1] === "order_no" && sortSetting[2] === "asc") {
                                // sortRows(allOrders, "dsc", "order_no",);
                                setSortSetting(["", "order_no", "dsc"])
                            }
                            else {
                                // sortRows(allOrders, "asc", "order_no",);
                                setSortSetting(["", "order_no", "asc"])
                            }
                        }}>
                        Or.No
                    </div>
                    <div className='OrdersTabContent-container__index__username OrdersTabContent-container__index__term'
                        onClick={() => {
                            if (sortSetting[1] === "useremail" && sortSetting[2] === "asc") {
                                // sortRows(allOrders, "dsc", "order_no",);
                                setSortSetting(["", "useremail", "dsc"])
                            }
                            else {
                                // sortRows(allOrders, "asc", "order_no",);
                                setSortSetting(["", "useremail", "asc"])
                            }
                        }}>
                        Customer
                    </div>
                    <div className='OrdersTabContent-container__index__source OrdersTabContent-container__index__term'
                        onClick={() => {
                            if (sortSetting[1] === "source_location" && sortSetting[2] === "asc") {
                                // sortRows(allOrders, "loc", "source_location",);
                                setSortSetting(["loc", "source_location", "dsc"])
                            }
                            else {
                                // sortRows(allOrders, "loc", "source_location",);
                                setSortSetting(["loc", "source_location", "asc"])
                            }
                        }}>
                        Source
                    </div>
                    <div className='OrdersTabContent-container__index__destination OrdersTabContent-container__index__term'
                        onClick={() => {
                            if (sortSetting[1] === "destination_location" && sortSetting[2] === "asc") {
                                // sortRows(allOrders, "loc", "destination_location",);
                                setSortSetting(["loc", "destination_location", "dsc"])
                            }
                            else {
                                // sortRows(allOrders, "loc", "destination_location",);
                                setSortSetting(["loc", "destination_location", "asc"])
                            }
                        }}>
                        Destination
                    </div>
                    <div className='OrdersTabContent-container__index__payload OrdersTabContent-container__index__term'
                        onClick={() => {
                            if (sortSetting[1] === "total_weight" && sortSetting[2] === "asc") {
                                // sortRows(allOrders, "dsc", "total_weight",);
                                setSortSetting(["", "total_weight", "dsc"])
                            }
                            else {
                                // sortRows(allOrders, "asc", "total_weight",);
                                setSortSetting(["", "total_weight", "asc"])
                            }
                        }}>
                        Payload
                    </div>
                    <div className='OrdersTabContent-container__index__delivery-type OrdersTabContent-container__index__term'
                        onClick={() => {
                            if (sortSetting[1] === "delivery_type" && sortSetting[2] === "asc") {
                                // sortRows(allOrders, "dsc", "delivery_type",);
                                // allOrders.sort((a, b) => {
                                //     if (dayjs(a["delivery_type"]) < dayjs(b["delivery_type"])) {
                                //         return 1;
                                //     }
                                //     return -1;
                                // })
                                setSortSetting(["", "delivery_type", "dsc"])
                            }
                            else {
                                // sortRows(allOrders, "asc", "delivery_type",);
                                // allOrders.sort((a, b) => {
                                //     if (dayjs(a["delivery_type"]) < dayjs(b["delivery_type"])) {
                                //         return -1;
                                //     }
                                //     return 1;
                                // })
                                setSortSetting(["", "delivery_type", "asc"])
                            }
                        }}>
                        Delivery Type
                    </div>
                    <div className='OrdersTabContent-container__index__delivery-time OrdersTabContent-container__index__term'
                        onClick={() => {
                            if (sortSetting[1] === "delivery_date" && sortSetting[2] === "asc") {
                                // sortRows(allOrders, "dsc", "delivery_date",);
                                setSortSetting(["", "delivery_date", "dsc"])
                            }
                            else {
                                // sortRows(allOrders, "asc", "delivery_date",);
                                setSortSetting(["", "delivery_date", "asc"])
                            }
                        }}>
                        Delivery Time
                    </div>
                    <div className='OrdersTabContent-container__index__status-update OrdersTabContent-container__index__term'
                        onClick={() => {
                            if (sortSetting[1] === "current_status.number" && sortSetting[2] === "asc") {
                                // // sortRows(allOrders, "dsc", "current_status.number",)
                                // allOrders.sort((a, b) => {
                                //     if (a.current_status.number < b.current_status.number) {
                                //         return 1;
                                //     }
                                //     else if (a.current_status.number > b.current_status.number) {
                                //         return -1;
                                //     }
                                //     else {
                                //         return 0;
                                //     }
                                // })
                                setSortSetting(["number", "current_status.number", "dsc"]);
                            }
                            else {
                                // // sortRows(allOrders, "asc", "current_status.number",);
                                // allOrders.sort((a, b) => {
                                //     if (a.current_status.number < b.current_status.number) {
                                //         return -1;
                                //     }
                                //     else if (a.current_status.number > b.current_status.number) {
                                //         return 1;
                                //     }
                                //     else {
                                //         return 0;
                                //     }
                                // })
                                setSortSetting(["number", "current_status.number", "asc"]);
                            }
                        }}>
                        status-update
                    </div>
                    <div className='OrdersTabContent-container__index__order-details OrdersTabContent-container__index__term'>
                        Order details
                    </div>

                </div>
                {!searchContentVisibility ? allOrdersLocal.map((order, odIdx) => {
                    return <div key={order._id} className='OrdersTabContent-datacontainer__row' >
                        <div className='OrdersTabContent-datacontainer__row__order-no OrdersTabContent-datacontainer__row__term'>
                            {order.order_no}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__username OrdersTabContent-datacontainer__row__term'>
                            {order.useremail.split("@")[0]}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__source OrdersTabContent-datacontainer__row__term'>
                            {locations[order.source_location]?.name}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__destination OrdersTabContent-datacontainer__row__term'>
                            {locations[order.destination_location]?.name}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__payload OrdersTabContent-datacontainer__row__term'>
                            {order.total_weight}g
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__delivery-type OrdersTabContent-datacontainer__row__term'>
                            {order.delivery_type}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__delivery-time OrdersTabContent-datacontainer__row__term'>
                            {order.delivery_type === "Urgent" ? `ASAP ${dayjs(order.delivery_date).format("YYYY-MM-DD HH:mm").toString()}` : dayjs(order.delivery_date).format("YYYY-MM-DD HH:mm").toString()}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__status-update OrdersTabContent-datacontainer__row__term'>
                            {order.current_status.name} {order.current_status.number < 3 && <button
                                className='OrdersTabContent-datacontainer__row__status-update__btn'
                                onClick={() => {
                                    // console.log("scrollHeight,scrollTop =", ordersListRef.current.scrollHeight, ordersListRef.current.scrollTop);
                                    // console.log(ordersListRef.current.scrollHeight - ordersListRef.current.scrollTop)
                                    // orderTabDataStore.scrollTop = ordersListRef.current.scrollTop
                                    saveScrollTop(ordersListRef.current.scrollTop)
                                    socket.emit('client:update_order_status', order)
                                    console.log("allOrders update Status btn =", allOrdersLocal)
                                }}
                            >{updateStatusLabels[order.current_status.name.toLocaleLowerCase()]}</button>}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__order-details OrdersTabContent-datacontainer__row__term'>
                            <button className='OrdersTabContent-datacontainer__row__order-details__btn'
                                onClick={() => { setSelectedOrder(odIdx); setOrderDetailsVisibility(true) }}
                            >Order details</button>
                        </div>

                    </div>
                }) : searchedItems.map((order, odIdx) => {
                    return <div key={order._id} className='OrdersTabContent-datacontainer__row'>
                        <div className='OrdersTabContent-datacontainer__row__order-no OrdersTabContent-datacontainer__row__term'>
                            {order.order_no}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__username OrdersTabContent-datacontainer__row__term'>
                            {order.useremail.split("@")[0]}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__source OrdersTabContent-datacontainer__row__term'>
                            {locations[order.source_location].name}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__destination OrdersTabContent-datacontainer__row__term'>
                            {locations[order.destination_location].name}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__payload OrdersTabContent-datacontainer__row__term'>
                            {order.total_weight}g
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__delivery-type OrdersTabContent-datacontainer__row__term'>
                            {order.delivery_type}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__delivery-time OrdersTabContent-datacontainer__row__term'>
                            {order.delivery_type === "Urgent" ? `ASAP ${dayjs(order.delivery_date).format("YYYY-MM-DD HH:mm").toString()}` : dayjs(order.delivery_date).format("YYYY-MM-DD HH:mm").toString()}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__status-update OrdersTabContent-datacontainer__row__term'>
                            {order.current_status.name} {order.current_status.number < 3 && <button
                                className='OrdersTabContent-datacontainer__row__status-update__btn'
                                onClick={() => {
                                    socket.emit('client:update_order_status', order)
                                }}
                            >update Status</button>}
                        </div>
                        <div className='OrdersTabContent-datacontainer__row__order-details OrdersTabContent-datacontainer__row__term'>
                            <button className='OrdersTabContent-datacontainer__row__order-details__btn'
                                onClick={() => { setSelectedOrder(odIdx); setOrderDetailsVisibility(true) }}
                            >Order details</button>
                        </div>

                    </div>
                })}
                {searchContentVisibility && searchedItems.length === 0 &&
                    <div
                        style={{ fontSize: "30px" }}
                    >no Orders found with this search setting
                    </div>}
            </div>
            {/* {orderDetailsVisibility && <OrderDetailsPopup setOpenModal={setOrderDetailsVisibility} order={orderDetails} />} */}
            {orderDetailsVisibility && <CustomPopUpTemplete setOpenModal={setOrderDetailsVisibility}
                content={<OrderDetailsTab
                    setShowPopup={setOrderDetailsVisibility}
                    item={!searchContentVisibility ? allOrdersLocal[selectedOrder] : searchedItems[selectedOrder]}
                    cancelStatusUpdate={() => { socket.emit('client:cancel-order', orderDetails) }}
                    handleNotifyAlert={handleNotifyAlert}
                    SetLoadingVisibility={SetLoadingVisibility}
                    userCookie={userCookie}
                    allNewPanelLog={allNewPanelLog}
                    updateOrdersPage={updateOrdersPageData} />}
            />}
        </div>
    )
}

export default OrdersTabContent