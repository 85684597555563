import React, { useEffect, useState } from 'react';
import "./AllDrones.scss"
import dayjs from 'dayjs';
import AddNewDroneForm from '../AddNewDrone/AddNewDroneForm';
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';
import DroneDetailPage from '../DroneDetailTab/DroneDetailpopUp';
//import AddNewModelForm from '../AddNewModel/AddNewModelForm';


const AllDronesTab = ({ allDrones, userCookie, setAllDrones, allLocations, setSelectedTab, SetLoadingVisibility, handleNotifyAlert,locations }) => {
    const [addDroneForm, setAddDroneForm] = useState(false)
    const [getModel, setGetModel] = useState([])
    const [droneDetailsPopup, setDroneDetailPopup] = useState(false);
    const [filteredDroneData, setFliteredDroneData] = useState([])
    const [seletedDrone, setSelectedDrone] = useState()

    const handleAddModelButton = () => {
        setAddDroneForm(true)
    }
    useEffect(() => {
        axios({
            url: backEndIP + "/dronemodel/get_drone_model",
            method: "GET",
            headers: {
                authorization: userCookie.token
            }
        }).then((modelData) => {
            //console.log(modelData.data)
            setGetModel(modelData.data.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        axios({
            url: backEndIP + "/drone/get_all_drones",
            method: "GET",
            headers: {
                authorization: userCookie.token
            }
        }).then((modelData) => {
            //console.log(modelData.data)
            setAllDrones(modelData.data.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    // const locations = {
    //     "63bd5b3ccafee0c35772f8f1": "Office",
    //     "63932c7ab1cab628335ae969": "Jengjal",
    //     "63930f82865ec3abed90bc1b": "Mendipathar PHC",
    //     "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
    //     "639311e2865ec3abed90bc23": "Nongalbibra PHC",
    //     "6393126c865ec3abed90bc27": "Shallang PHC",
    //     "639312ff865ec3abed90bc2b": "Samanda PHC",
    // }

    const handelFilterByHub = (e) => {
        console.log(e.target.value)
        //     if(e.target.value === "All"){
        //         setAllNodes(duplicateNode)  
        //     } else {
        //      const filteredData = []
        //      duplicateNode.forEach((element) => {
        //         console.log(element)
        //         if (element.relatedHubs === e.target.value) {
        //             return filteredData.push(element)
        //         } else {
        //             return null
        //         }
        //     })
        //    // console.log(filteredData)
        //     setAllNodes(filteredData)
        // }
    }
    useEffect(() => {
        setFliteredDroneData(allDrones.sort((a, b) => (a.internal_id.slice(0, 4) > b.internal_id.slice(0, 4) ? 1 : -1)))
       // console.log(allDrones)
    }, [allDrones])
   // console.log(allLocations)

   const handelSelcetedModel = (e) => {
    // console.log("Handel select Model", e.target.value)
    // console.log("allDrones", allDrones)
    if( e.target.value === "All"){
        setFliteredDroneData(allDrones.sort((a, b) => (a.internal_id.slice(0, 4) > b.internal_id.slice(0, 4) ? 1 : -1)))
    } else {
        const filteredDroneArray = allDrones.filter((item) => {
            // console.log(item)
            if (e.target.value === item.model_no) {
                return item
            }
        })
        setFliteredDroneData(filteredDroneArray.sort((a, b) => (a.internal_id.slice(0, 4) > b.internal_id.slice(0, 4) ? 1 : -1)))
    }
    
}

    //console.log(allDrones)
    return (
        <div className='MainDroneTab'>
            <div className='DroneTabContent-container'>
                {/* <div className='NodeTabContent-container__filter'> */}
                {/* <div className='NodeTabContent-container__filter__title'>Choose Hub : </div>
                    <select className='NodeTabContent-container__filter__options' onChange={(e) => { handelFilterByHub(e) }}>
                        <option>All</option>
                        {allLocations.state[].map((hub) => {
                            return <option value={hub._id}>{hub.location_name}</option>
                        })}
                    </select> */}

                {/* </div> */}
                <div className='DroneTabContentSearch'>
                <div className='DroneTabContentSearch__filter'><div className='DroneTabContentSearch__filter__title' >Select Model : </div>
                    <select className='DroneTabContentSearch__filter__options' 
                    onChange={(e) => { handelSelcetedModel(e) }}
                    >
                        <option>All</option>
                        {getModel.map((model) => {
                       
                            return <option key={`hubsOptions${model.full_model_name}`} value={model.full_model_name}>{model.full_model_name}</option>
                        })}
                    </select></div>
                    </div>

                <div className='DroneTabContent-datacontainer'>
                    <div className='DroneTabContent-container__index'>
                        <div className='DroneTabContent-container__index__droneNo DroneTabContent-container__index__term'>
                            Drone No
                        </div>
                        <div className='DroneTabContent-container__index__modelNo DroneTabContent-container__index__term'>
                            Model No
                        </div>
                        <div className='DroneTabContent-container__index__payload DroneTabContent-container__index__term' >
                            Payload Capacity(Kg)
                        </div>
                        <div className='DroneTabContent-container__index__maxRange DroneTabContent-container__index__term'>
                            Maximum Range(Km)
                        </div>
                        <div className='DroneTabContent-container__index__flightHrs DroneTabContent-container__index__term'>
                            Flight Hours (HH:mm:ss)
                        </div>
                        <div className='DroneTabContent-container__index__mfgDate DroneTabContent-container__index__term' >
                            Manufacturing Date
                        </div>
                        <div className='DroneTabContent-container__index__lmDate DroneTabContent-container__index__term'>
                            Last Maintenance Date
                        </div>
                        <div className='DroneTabContent-container__index__versionD DroneTabContent-container__index__term'>
                            Drone Version
                        </div>
                        <div className='DroneTabContent-container__index__pilotId DroneTabContent-container__index__term'>
                            Authorized Pilot
                        </div>
                        <div className='DroneTabContent-container__index__currLoca DroneTabContent-container__index__term' >
                            Current Location
                        </div>

                    </div>
                    {filteredDroneData.map((drone, i) => {
                       // console.log(drone.internal_id)
                        const droneV = drone.drone_version % 10;
                        const secondDigit = drone.drone_version / 10;
                        const version = secondDigit + "." + droneV
                        return <div key={drone._id} className='DroneTabContent-datacontainer__row' >
                            <div className='DroneTabContent-datacontainer__row__droneNo DroneTabContent-datacontainer__row__term'>
                                {drone.internal_id.slice(0, 4)}
                            </div>
                            <div className='DroneTabContent-datacontainer__row__modelNo DroneTabContent-datacontainer__row__term'>
                                {drone.model_no}
                            </div>
                            <div className='DroneTabContent-datacontainer__row__payload DroneTabContent-datacontainer__row__term'>
                                {drone.payload_capacity}Kg
                            </div>
                            <div className='DroneTabContent-datacontainer__row__maxRange DroneTabContent-datacontainer__row__term'>
                                {drone.max_range}Km
                            </div>
                            <div className='DroneTabContent-datacontainer__row__flightHrs DroneTabContent-datacontainer__row__term'>
                                {drone.flight_hours.hours + " : " + drone.flight_hours.minutes + " : " + drone.flight_hours.seconds}
                            </div>
                            <div className='DroneTabContent-datacontainer__row__mfgDate DroneTabContent-datacontainer__row__term'>
                                {dayjs(drone.manufacturing_date).format("YYYY-MM-DD HH:mm").toString()}
                            </div>
                            <div className='DroneTabContent-datacontainer__row__lmDate DroneTabContent-datacontainer__row__term'>
                                {dayjs(drone.last_maintenance_date).format("YYYY-MM-DD HH:mm").toString()}
                            </div>
                            <div className='DroneTabContent-datacontainer__row__versionD DroneTabContent-datacontainer__row__term'>
                                {version}
                            </div>
                            <div className='DroneTabContent-datacontainer__row__pilotId DroneTabContent-datacontainer__row__term'>
                                1.{drone.pilot_id1.split("@")[0]}<br /> 2.{drone.pilot_id2.split("@")[0]}
                            </div>
                            <div className='DroneTabContent-datacontainer__row__currLoca DroneTabContent-datacontainer__row__term'>
                                {locations[drone.current_location]}<button className="DroneTabContent-datacontainer__row__currLoca__Change__btn" onClick={() => { setDroneDetailPopup(true); setSelectedDrone(drone) }}>Change</button>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div className='AddNewDroneButton'><button className='AddNewDroneButton__Btn' onClick={() => { handleAddModelButton() }}>Register Drone</button></div>
            {addDroneForm ? <AddNewDroneForm setFormPopup={setAddDroneForm} allDrones={allDrones} getModel={getModel} userCookie={userCookie} setAllDrones={setAllDrones} setGetModel={setGetModel} setSelectedTab={setSelectedTab} SetLoadingVisibility={SetLoadingVisibility} handleNotifyAlert={handleNotifyAlert} /> : null}
            {droneDetailsPopup && <DroneDetailPage setDetailsPopUp={() => { setSelectedTab("Drones"); setDroneDetailPopup(false) }} setAllDrones={setAllDrones} allDrones={allDrones} droneDetailsPopup={seletedDrone} userCookie={userCookie} SetLoadingVisibility={SetLoadingVisibility} handleNotifyAlert={handleNotifyAlert} />}
        </div>
    )
}

export default AllDronesTab