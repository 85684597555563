import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import HubAddPopUp from "../HubAddPopUp/HubAddPopUp";
import { backEndIP } from "../MyConfiguration";
import "./CreateLocationPanel.scss";
import { selectHubNodeLocationsLoading, setHubNodeLocationsLoading } from "../../ReduxStore/HubNodeLocationsSlice";
import { useDispatch, useSelector } from "react-redux";

const InputFieldBox = ({ item, setNewLocationData }) => {
  const InputRef = useRef()
  const handleInputChange = () => {
    setNewLocationData((prev) => {
      let obj = { ...prev };
      obj[item.key] = InputRef.current.value;
      return obj;
    });
  };

  return (
    <div className="CreateNewNodeContainer__input-row-cont">
      <div className="CreateNewNodeContainer__input-row-cont__label"
      >{item.label}</div>
      <div> : </div>
      <input ref={InputRef}
        className="CreateNewNodeContainer__input-row-cont__input"
        type={item.type}
        onChange={handleInputChange}
      ></input>
    </div>
  );
};

const CreateLocationPanel = ({
  userCookie,
  setFormPopup,
  setAllNode,
  adminDesignation,
  duplicateNode,
  setDuplicateNode,
  allNode,
  allLocations,
  SetLoadingVisibility,
  handleNotifyAlert }) => {
  const modalRef = useRef();
  const [newLocationData, setNewLocationData] = useState([]);
  const [selectCountry, setSelectCountry] = useState("");
  const [selectZone, setSelectZone] = useState("");
  const [selectState, setSelectState] = useState("");
  const [selectHub, setSelectHub] = useState("")
  const dispatch = useDispatch()
  const hubNodeLocationsLoadingState = useSelector(selectHubNodeLocationsLoading)
  // console.log(newLocationData);
  const content = {
    outurl: "/hub/add_hub",
    input: [{ label: "Latitude", key: "latitude", type: "number" },
    { label: "Longitude", key: "longitude", type: "number" },
    { label: "Location Name", key: "location_name", type: "text" },
    { label: "District", key: "district", type: "text" },
    { label: "Full Address", key: "address", type: "text" },
    { label: "Pin code", key: "pin_code", type: "number" },
    { label: "Road Distane (KM)", key: "roadDistance", type: "number" },
    { label: "Road Time (hrs)", key: "roadTime", type: "string" }],
    drop_down: [{ label: "Country", key: "country_id", inurl: "/country/get-country" },
    { label: "Zone", key: "zone_id", inurl: "/zone/get_zone" },
    { label: "State", key: "state_id", inurl: "/state/get_state" },
    { label: "Nearest Hub", key: "hub_id", inurl: "/hub/getHub" }]
  }
  const closeModal = e => {
    if (modalRef.current === e.target) {
      //window.location.reload(false)
      //setSelectedTab("All Flights")
      setFormPopup(false);
    }
  };

  useEffect(() => {
    axios({
      url: backEndIP + "/location/adminAllLocations",
      // url: backEndIP + "/user/login",
      method: "GET",
      headers: {
        authorization: userCookie.token
      },
    }).then((locResp) => {
      // console.log("locationResp =", locResp.data.data)
      const allPreData = {
        ...newLocationData, ["country_id"]: locResp.data.data.countryNames._id !== "" ? locResp.data.data.countryNames._id : ""
        , ["zone_id"]: locResp.data.data.zonesNames._id !== "" ? locResp.data.data.zonesNames._id : "",
        ["state_id"]: locResp.data.data.stateNames._id !== "" ? locResp.data.data.stateNames._id : "",
        ["hub_id"]: locResp.data.data.hubsName._id !== "" ? locResp.data.data.hubsName._id : ""
      }
      // setZone(locResp.data.data.zonesNames && locResp.data.data.zonesNames)
      // setState(locResp.data.data.countryNames && locResp.data.data.countryNames)
      // console.log(allPreData.country_id)
      setNewLocationData(allPreData)
      //console.log(deploymentData.country_id)
      locResp.data.data.countryNames.name !== "" && setSelectCountry(locResp.data.data.countryNames.name)
      locResp.data.data.zonesNames.name !== "" && setSelectZone(locResp.data.data.zonesNames.name)
      locResp.data.data.stateNames.name !== "" && setSelectState(locResp.data.data.stateNames.name)
      locResp.data.data.hubsName.name !== "" && setSelectHub(locResp.data.data.hubsName.name)
      // console.log(country)
    }).catch((err) => {
      console.log(err);
    })
  }, [])

  // console.log("New Hub Data", newLocationData)
  //console.log("Country", selectCountry)
  // console.log("Zone", allLocations)
  const handleCreateNewLocation = () => {
    // console.log(newLocationData);
    SetLoadingVisibility(true);
    axios({
      url: backEndIP + "/location/add_location",
      method: "POST",
      headers: {
        authorization: userCookie.token,
      },
      data: {
        ...newLocationData,
      }
    })
      .then((resp) => {
        console.log(resp.data);
        setFormPopup(false);
        SetLoadingVisibility(false)
        setAllNode(resp.data.data.sort((a, b) => (Number(a.tag_name.slice(1)) > Number(b.tag_name.slice(1)) ? 1 : -1)))
        setDuplicateNode(resp.data.data.sort((a, b) => (Number(a.tag_name.slice(1)) > Number(b.tag_name.slice(1)) ? 1 : -1)))
        dispatch(setHubNodeLocationsLoading("idle"))
        handleNotifyAlert(
          "notify",
          `${resp.data.message}`,
          "success",
          () => { }
        );

      })
      .catch((err) => {
        SetLoadingVisibility(false);
        setFormPopup(false);
        handleNotifyAlert(
          "alert",
          `${err.response.data.message}`,
          "failed",
          () => { }
        );
      });
  }
  // console.log("State", selectState)
  // console.log(allLocations)
  // console.log("deploymentData", newLocationData)
  // console.log("country", selectCountry)
  // console.log("zone", selectZone)
  // console.log("state", selectState)
  // console.log("Hub", selectHub)
  return (
    <div onClick={closeModal} ref={modalRef} className="CreateNewNodeBackground">
      <div className="CreateNewNodeContainer">
        <div className="CreateNewNodeContainer__form">Add New Node</div>
        <div className="CreateNewNodeContainer__subContainer">
          <div className="CreateNewNodeContainer__left">
            {content.input.map((item) => {
              return <InputFieldBox key={item.key} item={item} setNewLocationData={setNewLocationData} userCookie={userCookie}></InputFieldBox>
            })}
          </div>
          {/* {content.drop_down.map((item) => {
          return <InputDropDownBox key={item.key} item={item} setInfoToSend={setInfoToSend} userCookie={userCookie}></InputDropDownBox>
      })} */}
          <div className="CreateNewNodeContainer__right">
            {newLocationData.country_id === "" ? <div className="CreateNewNodeContainer__field">
              <div className="CreateNewNodeContainer__field__title">Country</div>
              <div className="CreateNewNodeContainer__field__colon">:</div>
              <div className="CreateNewNodeContainer__field__value">
                <select className="CreateNewNodeContainer__field__value__option"
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectCountry(allLocations.countryNames[e.currentTarget.value])
                      setNewLocationData((prev) => {
                        // console.log("select", selectCountry)
                        let obj = { ...prev };
                        obj[content.drop_down[0].key] = e.target.value;
                        return obj;
                      });
                    } else {
                      setSelectCountry("");
                      setSelectZone("");
                      setSelectState("");
                      setSelectHub("");
                    }
                    // console.log(e.currentTarget.value);
                  }}>
                  <option value={""} className="CreateNewNodeContainer__field__value__option"></option>
                  {allLocations.country.map((item) => {
                    // console.log(item)
                    return <option value={item._id} className="CreateNewNodeContainer__field__value__option">{item.name}</option>
                  })}
                </select>
              </div>
            </div>:
            <div className="CreateNewNodeContainer__field">
            <div className="CreateNewNodeContainer__field__title">Country</div>
            <div className="CreateNewNodeContainer__field__colon">:</div>
            <div className="CreateNewNodeContainer__field__value">
                {allLocations.country.map((item) => {
                  if(newLocationData.country_id === item._id)
                 // setCountry(item._id)
                  return <div 
                  style={{paddingLeft: "calc(5px * var(--heightmultiplier)"}}
                  className="CreateNewNodeContainer__field__value__option"
                   value={item._id}>{item.name}</div>
                })}
              {/* </select> */}
            </div>
          </div>
            }
            {selectCountry !== "" && <>{newLocationData.zone_id === "" ? <div className="CreateNewNodeContainer__field">
              <div className="CreateNewNodeContainer__field__title">Zone</div>
              <div className="CreateNewNodeContainer__field__colon">:</div>
              <div className="CreateNewNodeContainer__field__value">
                <select className="CreateNewNodeContainer__field__value__option"
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectZone(allLocations.zonesNames[e.currentTarget.value])
                      setNewLocationData((prev) => {
                        // console.log("select", selectZone)
                        let obj = { ...prev };
                        obj[content.drop_down[1].key] = e.target.value;
                        return obj;
                      });
                    }
                    else {
                      setSelectZone("");
                      setSelectState("");
                      setSelectHub("");
                    }
                    // console.log(e.currentTarget.value);
                  }}>
                  <option className="CreateNewNode-container__field__value__option" value={""}></option>
                  {allLocations.zones.map((item) => {
                    return <option value={item._id} className="CreateNewNode-container__field__value__option">{item.name}</option>
                  })}
                </select>
              </div>
            </div>:
            <div className="CreateNewNodeContainer__field">
            <div className="CreateNewNodeContainer__field__title">Zone</div>
            <div className="CreateNewNodeContainer__field__colon">:</div>
            <div className="CreateNewNodeContainer__field__value">
                {allLocations.zones.map((item) => {
                  // console.log("Zone", newLocationData.zone_id, item._id)
                  if(newLocationData.zone_id === item._id)
                 // setCountry(item._id)
                  return <div 
                  style={{paddingLeft: "calc(5px * var(--heightmultiplier)"}}
                  className="CreateNewNodeContainer__field__value__option"
                   value={item._id}>{item.name}</div>
                })}
              {/* </select> */}
            </div>
          </div>}</>}
            {selectZone !== "" && <>{newLocationData.state_id === "" ? <div className="CreateNewNodeContainer__field">
              <div className="CreateNewNodeContainer__field__title">State</div>
              <div className="CreateNewNodeContainer__field__colon">:</div>
              <div className="CreateNewNodeContainer__field__value">
                <select className="CreateNewNodeContainer__field__value__option"
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectState(allLocations.stateNames[e.currentTarget.value])
                      setNewLocationData((prev) => {
                        // console.log("select", selectState)
                        let obj = { ...prev };
                        obj[content.drop_down[2].key] = e.target.value;
                        return obj;
                      });
                    }
                    else {
                      setSelectState("");
                      setSelectHub("");
                    }
                    // console.log(e.currentTarget.value);
                  }}>
                  <option value={""} className="CreateNewNodeContainer__field__value__option"></option>
                  {allLocations.states[selectZone].map((item) => {
                    return <option value={item._id} className="CreateNewNodeContainer__field__value__option">{item.name}</option>
                  })}
                </select>
              </div>
            </div>:
             <div className="CreateNewNodeContainer__field">
             <div className="CreateNewNodeContainer__field__title">State</div>
             <div className="CreateNewNodeContainer__field__colon">:</div>
             <div className="CreateNewNodeContainer__field__value">
                 {allLocations.states[selectZone].map((item) => {
                   if(newLocationData.state_id === item._id)
                  // setCountry(item._id)
                   return <div 
                   style={{paddingLeft: "calc(5px * var(--heightmultiplier)"}}
                   className="CreateNewNodeContainer__field__value__option"
                    value={item._id}>{item.name}</div>
                 })}
               {/* </select> */}
             </div>
           </div>
            }</>
            }
            {selectState !== "" && <>{newLocationData.hub_id === "" ? <div className="CreateNewNodeContainer__field">
              <div className="CreateNewNodeContainer__field__title">Hub</div>
              <div className="CreateNewNodeContainer__field__colon">:</div>
              <div className="CreateNewNodeContainer__field__value">
                <select className="CreateNewNodeContainer__field__value__option"
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectHub(allLocations.hubNames[e.currentTarget.value])
                      setNewLocationData((prev) => {
                        // console.log("select", selectHub)
                        let obj = { ...prev };
                        obj[content.drop_down[3].key] = e.target.value;
                        return obj;
                      });
                    }
                    else {
                      setSelectState("");
                      setSelectHub("");
                    }
                    // console.log(e.currentTarget.value);
                  }}>
                  <option value={""} className="CreateNewNodeContainer__field__value__option"></option>
                  {allLocations.hubs[selectState].map((item) => {
                    // console.log(item)
                    return <option value={item._id} className="CreateNewNodeContainer__field__value__option">{item.name}</option>
                  })}
                </select>
              </div>
            </div>:
             <div className="CreateNewNodeContainer__field">
             <div className="CreateNewNodeContainer__field__title">Hub</div>
             <div className="CreateNewNodeContainer__field__colon">:</div>
             <div className="CreateNewNodeContainer__field__value">
                 {allLocations.hubs[selectState].map((item) => {
                   if(newLocationData.hub_id === item._id)
                  // setCountry(item._id)
                   return <div 
                   style={{paddingLeft: "calc(5px * var(--heightmultiplier)"}}
                   className="CreateNewNodeContainer__field__value__option"
                    value={item._id}>{item.name}</div>
                 })}
               {/* </select> */}
             </div>
           </div>}</>
            }
          </div>
        </div>
        <div className="CreateNewNodeContainer__bottom">
          {selectState !== "" && <button className="CreateNewNodeContainer__bottom__createNodeBtn"
            onClick={handleCreateNewLocation}
          >
            Create Node
          </button>}
        </div>
      </div>

      {/* {showAddPopup ? <HubAddPopUp setOpenModal={setShowAddPopup} setSelectedTab={setSelectedTab} messageToDisplay={{ message: "New Hub Added Succesfully" , func: () => { } }} /> : null} */}
    </div>
  );
};

export default CreateLocationPanel;
