import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import "./FlightDetailsPopup.scss";
import FlightStatusIndicator from "../FlightStatusIndicator/FlightStatusIndicator";
import FileUploadInput from "../FileUploadInput/FileUploadInput";
import SuccessModelPopUp from "../FileUploadInput/FileUploadResultPopUp";
import axios from "axios";
import { backEndIP } from "../MyConfiguration";
import ConfirmFlightPopUp from "./FlightMarkCompletedDone";

function FlightDetailsPopup({ setOpenModal, setFlightDetails, flight, setCheckListDetailsPopup, setCheckListDetails, setPageTitle, userCookie, handleNotifyAlert,
  SetLoadingVisibility }) {
  const modalRef = useRef();
  const [uploadDone, setUploadDone] = useState(false)
  const [result, setResult] = useState({
    result: "",
    message: ""
  })
  const [abortReason, setAbortReason] = useState({
    abort: false,
    status: "",
    reason: ""
  })
  const [showCompletedPopUp, setShowCompletedPopUp] = useState(false)
  // const [canAddLog, setCanAddLog] = useState(true)
  // const [uploadFileBefor, setUploadFilebefor] = useState()


  const closeModal = e => {
    if (modalRef.current === e.target) {
      setOpenModal();
    }
  };

  const locations = {
    "63932c7ab1cab628335ae969": "Jengjal",
    "63930f82865ec3abed90bc1b": "Mendipathar PHC",
    "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
    "639311e2865ec3abed90bc23": "Nongalbibra PHC",
    "6393126c865ec3abed90bc27": "Shallang PHC",
    "639312ff865ec3abed90bc2b": "Samanda PHC",
  }
  const list = {
    "date_created": "Date",
    "start_time": "Date",
    "end_time": "Date",
    "start_location": "Location",
    "end_location": "Location",
    "hub_id": "Location",
    "time_taken": "time",
    "_id": "Flight ID",
    "localFlightId": "Flight ID",
  }
  const CompileContent = (data) => {
    if (list[data] === "Date") {
      return dayjs(flight[data]).format("YYYY-MM-DD HH:mm").toString();
    }
    else if (list[data] === "Location") {
      return locations[flight[data]]
    }
    else if (list[data] === "time") {
      return dayjs().set('hour', 0).set('minute', 0).set('second', 0).add(flight[data], 'second').format('HH [Hours] : mm [Minutes] : ss [Seconds]');
    }
    return flight[data];
  }

  // const calculateDate = (() => {
  //   let endTime = new Date(flight.end_time)
  //   let today = new Date()
  //   endTime.setDate(endTime.getDate() + 2)
  //   let difference = endTime - today;
  //   today.getDate()
  //   if (today > endTime) {
  //     setCanAddLog(false)
  //   } else {
  //     let diffInSeconds = Math.floor(difference / 1000);
  //     let diffInMinutes = Math.floor(diffInSeconds / 60);
  //     let diffInHours = Math.floor(diffInMinutes / 60);
  //     let diffInDays = Math.floor(diffInHours / 24);

  //     // Calculate remaining hours, minutes, and seconds
  //     let remainingHours = diffInHours % 24;
  //     let remainingMinutes = diffInMinutes % 60;
  //     let remainingSeconds = diffInSeconds % 60;
  //     const lastDate = `${diffInDays > 1 ? `${diffInDays}days` : `${diffInDays}day`}, ${remainingHours}hr, ${remainingMinutes}min, ${remainingSeconds}sec`
  //     console.log(`${diffInDays > 1 ? `${diffInDays}days` : `${diffInDays}day`}, ${remainingHours}hr, ${remainingMinutes}min, ${remainingSeconds}sec`)
  //     setUploadFilebefor(lastDate)
  //   }
  // })

  const handelAbortFlight = () => {
    // const statusValue
    SetLoadingVisibility(true)
    if (abortReason.abort && abortReason.reason !== "") {
      const data = {
        status: abortReason.abort,
        reason: abortReason.reason,
      }
      axios({
        url: backEndIP + `/flight/abort_flight/${flight._id}`,
        method: "PUT",
        headers: {
          authorization: userCookie.token,
        },
        data: data
      }).then((resp) => {
        console.log("resp", resp.data)
        SetLoadingVisibility(false)
        setOpenModal()
        // setTotalItems(resp.data.length)
        setFlightDetails(resp.data.data)
        // setSearchedFlights(resp.data.data)
      }).catch((err) => {
        console.log(err)
        SetLoadingVisibility(false);
        handleNotifyAlert(
          "notify",
          `${err.response.data.message}`,
          "failed",
          () => { }
        );
      })
    } else {
      SetLoadingVisibility(false);
      handleNotifyAlert(
        "notify",
        `Please enter the reason for cancellation`,
        "failed",
        () => { }
      );
    }

  }

  const handelConfirmFlightCompleted = () => {
    SetLoadingVisibility(true)
    axios({
      url: backEndIP + `/flight/flight_completed/${flight._id}`,
      method: "PUT",
      headers: {
        authorization: userCookie.token,
      }
    }).then((resp) => {
      console.log("resp", resp.data)
      SetLoadingVisibility(false)
      setShowCompletedPopUp(false)
      setOpenModal()
      // setTotalItems(resp.data.length)
      setFlightDetails(resp.data.data)
      // setSearchedFlights(resp.data.data)
    }).catch((err) => {
      console.log(err)
      SetLoadingVisibility(false);
      setShowCompletedPopUp(false)
      handleNotifyAlert(
        "notify",
        `${err.response.data.message}`,
        "failed",
        () => { }
      );
    })
  }

  const [localFlight, setLocalFlight] = useState([])
  const flight_Data = {
    // "_id": "Flight ID",
    // "localFlightId": "Flight ID",
    "drone_id": "Drone ID",
    "pilot_id1": "Pilot ID",
    "pilot_id2": "Co-Pilot ID",
    "hub_id": "Hub Location",
    "date_created": "Date Created",
    "mission_file_name": "Mission File Name",
    "start_time": "Start Time",
    "end_time": "End Time",
    "time_taken": "Time Taken(HH:mm:ss)",
    "start_location": "Start Location",
    "end_location": "End Location",
    "distance_coverved": "Distance Covered(Km)",
    "payload": "Payload(grams)",
    "flight_type": "Flight Type",
    "order_no": "Order No",
  }
  useEffect(() => {
    const ownFlight = [];
    const keys = Object.keys(flight);
    keys.forEach(key => {
      if (flight_Data[key] !== undefined)
        ownFlight.push([flight_Data[key], CompileContent(key)])
    });
    //ownFlight.pop()
    // console.log("ownFlight", ownFlight)
    setLocalFlight(ownFlight)
    // calculateDate()

  }, [flight])




  // console.log("canAddLog", canAddLog)
  console.log("flight.preflightData", flight)
  return (
    <div onClick={closeModal} ref={modalRef} className="FlightDetailsPopup-modalBackground">
      <div className="FlightDetailsPopup-modalContainer" >
        <div className="FlightDetailsPopup-modalContainer__heading" >Flight Details</div>
        {flight.isAborted ? <div className='FlightDetailsPopup-modalContainer__statusBar'>
          <div className='FlightDetailsPopup-modalContainer__statusBar__title'>Flight Status: <div className='FlightDetailsPopup-modalContainer__statusBar__title__value'>{` Aborted`}</div></div>
          <div className='FlightDetailsPopup-modalContainer__statusBar__reason'>{flight.finalStatus.reason}</div>
        </div>
          : <div className='FlightDetailsPopup-modalContainer__statusBar'>
            <FlightStatusIndicator flightData={flight} />
          </div>}
        <div className="FlightDetailsPopup-modalContainer__dataTab">
          <div className="FlightDetailsPopup-modalContainer__dataTab__left">
            <div className="FlightDetailsPopup-modalContainer__dataTab__left__item" >
              <div className="FlightDetailsPopup-modalContainer__dataTab__left__item__title">Flight ID</div>
              :
              <div className="FlightDetailsPopup-modalContainer__dataTab__left__item__value"> {flight.localFlightId?flight.localFlightId:"-"}</div>
            </div> 
              <div className="FlightDetailsPopup-modalContainer__dataTab__left__item" >
                <div className="FlightDetailsPopup-modalContainer__dataTab__left__item__title">Internal Flight ID</div>
                :
                <div className="FlightDetailsPopup-modalContainer__dataTab__left__item__value">{flight._id}</div>
              </div>
            
            {localFlight.map((item, i) => {
              return <div className="FlightDetailsPopup-modalContainer__dataTab__left__item" key={i + 1}>
                <div className="FlightDetailsPopup-modalContainer__dataTab__left__item__title">{item[0]}</div>
                :
                <div className="FlightDetailsPopup-modalContainer__dataTab__left__item__value"> {item[1]}</div>
              </div>
            })}
          </div>
          <div className="FlightDetailsPopup-modalContainer__dataTab__right">
            {flight.isPreFlightChecklistCompleted && <div className="FlightDetailsPopup-modalContainer__dataTab__container">
              <div className="FlightDetailsPopup-modalContainer__dataTab__container__title">Pre Flight CheckList Details</div>
              <button className="FlightDetailsPopup-modalContainer__dataTab__container__button" onClick={() => {
                setCheckListDetailsPopup(true);
                setCheckListDetails(flight.preFlightChecklist);
                setPageTitle("Pre Flight Check List Data")
              }}>View</button>
            </div>}
            {flight.isPostFlightChecklistCompleted && <div className="FlightDetailsPopup-modalContainer__dataTab__container">
              <div className="FlightDetailsPopup-modalContainer__dataTab__container__title">Post Flight CheckList Details</div>
              <button className="FlightDetailsPopup-modalContainer__dataTab__container__button"
                onClick={() => {
                  setCheckListDetailsPopup(true);
                  setCheckListDetails(flight.postFlightChecklist);
                  setPageTitle("Post Flight Check List Data")
                }}>View</button>
            </div>}
            {!flight.isAborted && <div className="FlightDetailsPopup-modalContainer__dataTab__FileUpload">
              {<>{flight.flightLog !== undefined && flight.flightLog !== null ?
                <FileUploadInput
                  setOpenModal={setOpenModal}
                  flightId={flight._id}
                  setFlightDetails={setFlightDetails}
                  flightLogData={flight.flightLog}
                  userCookie={userCookie}
                  setUploadDone={setUploadDone}
                  setResult={setResult}
                  result={result}
                /> :
                <div className="FlightDetailsPopup-modalContainer__dataTab__FileUpload__error">Cannot add flight log</div>}</>}
            </div>}
            {!flight.isCompleted && !flight.isAborted && flight.isPreFlightChecklistCompleted && <div className="FlightDetailsPopup-modalContainer__dataTab__markCompelete">
              <button className="FlightDetailsPopup-modalContainer__dataTab__markCompelete__btn" onClick={() => { setShowCompletedPopUp(true) }}>Mark Completed</button>
            </div>}
            <div className="FlightDetailsPopup-modalContainer__dataTab__abortContainer">
              {abortReason.abort && <div className="FlightDetailsPopup-modalContainer__dataTab__abortContainer__inputContainer" >
                <div className="FlightDetailsPopup-modalContainer__dataTab__abortContainer__inputContainer__title">Enter Reason :</div>
                <textarea type="text"
                  value={abortReason.reason}
                  placeholder="Reason for aborting flight"
                  className="FlightDetailsPopup-modalContainer__dataTab__abortContainer__inputContainer__inputfield"
                  onChange={(e) => { setAbortReason({ ...abortReason, ["reason"]: e.target.value }) }} /></div>}
              {!flight.isCompleted && !abortReason.abort && !flight.isAborted && <div className="FlightDetailsPopup-modalContainer__dataTab__abortContainer__abortCompelete">
                <button className="FlightDetailsPopup-modalContainer__dataTab__abortContainer__abortCompelete__btn" onClick={() => setAbortReason({ ...abortReason, ["abort"]: true })}>Abort Flight</button>
              </div>}
              {abortReason.abort && <div className="FlightDetailsPopup-modalContainer__dataTab__abortContainer__abortCompelete">
                <button className="FlightDetailsPopup-modalContainer__dataTab__abortContainer__abortCompelete__btn" onClick={() => handelAbortFlight()}>Confirm Abort</button>
              </div>}
            </div>
          </div>
        </div>
        <div className="FlightDetailsPopup-modalContainer__OkayBtn" onClick={() => { modalRef.current.click() }}>OK</div>
      </div>
      {uploadDone && <SuccessModelPopUp result={result.result} message={result.message} />}
      {showCompletedPopUp && <ConfirmFlightPopUp setOpenModal={setShowCompletedPopUp} handelConfirmCompleted={handelConfirmFlightCompleted} />}
    </div>
  );
}

export default FlightDetailsPopup;
