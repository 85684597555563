import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import "./DashboardPage.scss";
import axios from 'axios';
import { backEndIP } from '../../components/MyConfiguration';

const allPagesData = {
    "Monitor Drone": { pageUrl: "monitor", imgUrl: "./MonitorDroneCard.png" },
    "View All Drones": { pageUrl: "view-all-drones", imgUrl: "./ViewAllDronesCard.png" },
    "Monitor All Drones": { pageUrl: "monitor-all-drones", imgUrl: "./MonitorAllDronesCard.png" },
    "View Flight": { pageUrl: "view-flight", imgUrl: "./ViewFlightCard.png" },
    "Admin Panel": { pageUrl: "adminpanel", imgUrl: "./AdminPanelCard.png" },
    "Airspace": { pageUrl: "airspace", imgUrl: "./airspace.png" },
}
const allPages = [
    "Monitor Drone",
    "Monitor All Drones",
    "Admin Panel",
    "View All Drones",
    "View Flight",
    "Airspace"
]

const DashboardPageCard = ({ title, imgUrl, navigateTo }) => {
    const navigate = useNavigate();
    return (
        <div className='DashboardPage-PageCard-container'
            onClick={() => { navigate("/" + navigateTo) }} >
            <img className='DashboardPage-PageCard-container__image' src={imgUrl} alt={title} />
            {/* <div className='DashboardPage-PageCard-container__title'>{title}</div> */}
        </div>
    )
}
const DashboardPage = ({ userCookie }) => {
    const navigate = useNavigate();
    const [userAvailableModules, setUserAvailableModules] = useState([])
    useEffect(() => {
        axios(`${backEndIP}/designation/get-my-modules`, {
            method: "GET",
            headers: { authorization: userCookie.token }
        }).then(designationsResp => {
            // console.log("designationsResp", designationsResp?.data)
            if (Array.isArray(designationsResp?.data?.data)) {
                setUserAvailableModules(designationsResp?.data?.data);
                if (designationsResp?.data?.data.length === 1 && designationsResp?.data?.data) {
                    navigate("/" + allPagesData[designationsResp?.data?.data[0]].pageUrl)
                }
            }
        }).catch(error => {
            console.log(error?.response?.data)
        })
    }, [userCookie.token])

    return (
        <div className='DashboardPage-container'>
            {allPages.map((page, i) => {
                if (userAvailableModules.includes(page))
                    return <DashboardPageCard key={page + i} title={page} navigateTo={allPagesData[page].pageUrl} imgUrl={allPagesData[page].imgUrl} />
            })}
        </div>
    )
}

export default DashboardPage