import React, { useRef } from "react";
import "./CustomPopUpTemplete.scss";

function CustomPopUpTemplete({ setOpenModal, content, pageTitle }) {
  const modalRef = useRef();

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setOpenModal(false);
    }
  };


  return (
    <div onClick={closeModal} ref={modalRef} className="CustomPopUpTempleteBackground">
      <div className="CustomPopUpTempleteContainer" >
        {pageTitle !== undefined && <div className="CustomPopUpTempleteContainer__title">{pageTitle}</div>}
        <div className="CustomPopUpTempleteContainer__msg">{content}</div>
        <button className="CustomPopUpTempleteContainer__close" onClick={() => { modalRef.current.click(); }} >X</button>
      </div>
    </div>

  );
}

export default CustomPopUpTemplete;
