import React, { useRef, useEffect, useState } from "react";
import "./FlightFilterPopUp.scss";
import axios from "axios";
import { backEndIP } from "../MyConfiguration";
import dayjs from "dayjs";

const FlightFilterPopUp = ({ setFormPopup, userCookie, valueToFilter, setValueToFilter, socket, allFlights, setSearchedFlights, setDuration, setFilterBy, setFlightSourceLocation, adminLocation, locations }) => {
    const modalRef = useRef();
    const [sourceLocation, setSourceLocation] = useState([])
    const DurationOptions = {
        "Today": dayjs().subtract(1, "day").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 3 days": dayjs().subtract(3, "day").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 1 week": dayjs().subtract(1, "week").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 2 weeks": dayjs().subtract(2, "week").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 1 Month": dayjs().subtract(1, "month").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 3 Months": dayjs().subtract(3, "month").set('hour', 0).set('minute', 0).set('second', 0),
    }

    const closeModal = e => {
        if (modalRef.current === e.target) {
            //window.location.reload(false)
            //setSelectedTab("All Flights")
            setFormPopup(false);
        }
    };

    useEffect(() => {
        axios({
            url: backEndIP + "/hub/hub-node-all-locations",
            // url: backEndIP + "/user/login",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then((hubResp) => {
            // console.log(hubResp.data)
            setSourceLocation(hubResp.data.data);
            //console.log(allHub)
        }).catch((err) => {
            console.log(err);
        })
    }, [])


    const handleFilterChange = async () => {
        // console.log("valueToFilter", valueToFilter)
        await socket.emit('client:get_all_flights', { filterBy: valueToFilter.filter, duration: DurationOptions[valueToFilter.duration.d] })
        // console.log("valueToFilter.sourceLocation !== adminLocation", valueToFilter.sourceLocation !== adminLocation,  valueToFilter.sourceLocation, adminLocation)
        if (valueToFilter.sourceLocation !== adminLocation && valueToFilter.sourceLocation !== "ALL") {
            const filteredArray = allFlights.filter((item) => {
                if (item.start_location === valueToFilter.sourceLocation) {
                    return item
                }
            })
            // console.log("filteredArray", filteredArray)
            await setSearchedFlights(filteredArray)
        } else {
            await setSearchedFlights(allFlights)
        }
        setFormPopup(false)
    }

    // console.log("valueToFilter", valueToFilter)
    // console.log("location", sourceLocation)
    return (
        <div onClick={closeModal} ref={modalRef} className="flightFilterPopUp">
            <div className="flightFilterPopUpContainer">
                <div className="flightFilterPopUpContainer__title">Filter PopUp</div>
                <div className='flightFilterPopUp-container'>
                    <div className='flightFilterPopUp-container__filter'>
                        <div className='flightFilterPopUp-container__filter__title'>Filter by : </div>
                        <div className='flightFilterPopUp-container__filter__options'>
                            <button className={valueToFilter.filter === "ALL" ? 'flightFilterPopUp-container__filter__options__selected-item' : 'flightFilterPopUp-container__filter__options__item'}
                                onClick={(e) => { setValueToFilter({ ...valueToFilter, ["filter"]: "ALL" }); setFilterBy("ALL") }}>ALL</button>
                            <button className={valueToFilter.filter === "Order Flight" ? 'flightFilterPopUp-container__filter__options__selected-item' : 'flightFilterPopUp-container__filter__options__item'}
                                onClick={(e) => { setValueToFilter({ ...valueToFilter, ["filter"]: "Order Flight" }); setFilterBy("Order Flight") }}>Order Flight</button>
                            <button className={valueToFilter.filter === "Return Flight" ? 'flightFilterPopUp-container__filter__options__selected-item' : 'flightFilterPopUp-container__filter__options__item'}
                                onClick={(e) => { setValueToFilter({ ...valueToFilter, ["filter"]: "Return Flight" }); setFilterBy("Return Flight") }}>Return Flight</button>
                            <button className={valueToFilter.filter === "Test Flight" ? 'flightFilterPopUp-container__filter__options__selected-item' : 'flightFilterPopUp-container__filter__options__item'}
                                onClick={(e) => { setValueToFilter({ ...valueToFilter, ["filter"]: "Test Flight" }); setFilterBy("Test Flight") }}>Test Flight</button>
                            <button className={valueToFilter.filter === "Forward Flight" ? 'flightFilterPopUp-container__filter__options__selected-item' : 'flightFilterPopUp-container__filter__options__item'}
                                onClick={(e) => { setValueToFilter({ ...valueToFilter, ["filter"]: "Forward Flight" }); setFilterBy("Forward Flight") }}>Forward Flight</button>
                        </div>
                    </div>
                    <div className='flightFilterPopUp-container__duration'>
                        <div className="flightFilterPopUp-container__duration__container">
                            <div className='flightFilterPopUp-container__duration__container__duration-title'>Duration :</div>
                            <select className='flightFilterPopUp-container__duration__container__duration-select'
                                defaultValue={valueToFilter.duration.d}
                                onChange={(e) => {
                                    valueToFilter.duration.d = e.currentTarget.value
                                    setValueToFilter({ ...valueToFilter, ["d"]: valueToFilter.duration.d })
                                    setDuration(DurationOptions[valueToFilter.duration.d])
                                }}>
                                <option className='flightFilterPopUp-container__duration__container__duration-select__option'>Today</option>
                                <option className='flightFilterPopUp-container__duration__container__duration-select__option'>Last 3 days</option>
                                <option className='flightFilterPopUp-container__duration__container__duration-select__option'>Last 1 week</option>
                                <option className='flightFilterPopUp-container__duration__container__duration-select__option'>Last 2 weeks</option>
                                <option className='flightFilterPopUp-container__duration__container__duration-select__option'>Last 1 Month</option>
                                <option className='flightFilterPopUp-container__duration__container__duration-select__option'>Last 3 Months</option>
                            </select>
                        </div>
                        <div className="flightFilterPopUp-container__duration__container">
                            <div className='flightFilterPopUp-container__duration__container__duration-title'>Source Location :</div>
                            <select className='flightFilterPopUp-container__duration__container__duration-select'
                                defaultValue={valueToFilter.sourceLocation === "ALL" || valueToFilter.sourceLocation === adminLocation ? "ALL" : locations[valueToFilter.sourceLocation].name}
                                // value={"All"}
                                onChange={(e) => {
                                    // console.log("E.target.value", e.currentTarget.value)
                                    valueToFilter.sourceLocation = e.currentTarget.value
                                    setValueToFilter({ ...valueToFilter, ["sourceLocation"]: valueToFilter.sourceLocation})
                                    setFlightSourceLocation(e.currentTarget.value)
                                }}
                            >
                                <option className='flightFilterPopUp-container__duration__container__duration-select__option'>{valueToFilter.sourceLocation === "ALL" || valueToFilter.sourceLocation === adminLocation ? "ALL" : locations[valueToFilter.sourceLocation].name}</option>
                                {sourceLocation.map((item, i) => {
                                    // console.log("item", item)
                                    return <option className='flightFilterPopUp-container__duration__container__duration-select__option' value={item._id} key={i + 1}>{item.name}</option>
                                })}
                                 <option className='flightFilterPopUp-container__duration__container__duration-select__option' value="ALL" key={0}>ALL</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="flightFilterPopUpButton">
                    <div className="flightFilterPopUpButton__apply" onClick={handleFilterChange}>
                        Apply</div>
                </div>
            </div>
        </div>

    );
}

export default FlightFilterPopUp;
