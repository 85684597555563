import axios from "axios";
import { React, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Register.scss";
import { backEndIP } from "../MyConfiguration";

const Register = (props) => {

  const usernameElementRef = useRef();
  const userEmailElementRef = useRef();
  const passwordElementRef = useRef();
  const confirmPasswordElementRef = useRef();
  const errorMessageElementRef = useRef();
  const designationElementRef = useRef();
  const designationNewElementRef = useRef();
  const [designationVal, setDesignationVal] = useState("Done Pilot")
  const navigate = useNavigate();

  const designation_arr = ["Drone Pilot", "Hub Manager"]

  const handleRegisterBtnClick = () => {
    let username = usernameElementRef.current.value;
    let userEmail = userEmailElementRef.current.value;
    let password = passwordElementRef.current.value;
    let confirmPassword = confirmPasswordElementRef.current.value;
    let designationNew = designationNewElementRef.current.value;
    console.log(username, password, confirmPassword);
    if (designationNew === "Others") {
      designationNew = designationElementRef.current.value;
      console.log(designationElementRef.current.value, designationNew)
    }
    if (username.length >= 6 && password.length > 5 && userEmail.length > 5) {
      if (password === confirmPassword && password.length >= 6) {
        errorMessageElementRef.current.style.opacity = 0;
        axios({
          url: backEndIP + "/admin/register",
          // url: backEndIP + "/user/register",
          method: "POST",
          headers: {},
          data: {
            useremail: userEmail,
            username: username,
            password: password,
            designation: designationNew,
          },
        })
          .then(async (loginData) => {
            if (loginData.data.status === "success") {
              window.alert(loginData.data.message);
            }
            console.log(loginData.data);
            navigate("/");
          })
          .catch((err) => {
            console.log(err)
            if (err.message) window.alert(err.message);
            console.log(err);
          });
      } else {
        if (password.length < 6) {
          errorMessageElementRef.current.innerHTML =
            "minimum length of password should be 6";
          errorMessageElementRef.current.style.opacity = 1;
        } else {
          errorMessageElementRef.current.innerHTML = "password does not match";
          errorMessageElementRef.current.style.opacity = 1;
        }
      }
    } else {
      if (username.length < 6) {
        errorMessageElementRef.current.innerHTML = "pls enter username";
        errorMessageElementRef.current.style.opacity = 1;
      } else {
        errorMessageElementRef.current.innerHTML = "pls enter password";
        errorMessageElementRef.current.style.opacity = 1;
      }
    }
  };
  const handelOther = (e) => {
    setDesignationVal(e.target.value)
  }
  return (
    <div className="register-page-body" style={{ height: "100vh", width: "100vw" }}>
      <div className="register-page-body__leftHalf">
        <div className="register-page-body__leftHalf__welcomeBox">
          <img className="register-page-body__leftHalf__welcomeBox__logo"
            src="clear sky logo mark.svg" alt="logo"
          >
          </img>
          <div className="register-page-body__leftHalf__welcomeBox__welcomeMsg">Welcome to ClearSky</div>
          <div className="register-page-body__leftHalf__welcomeBox__register-message">To Register Please Fill the form Below</div>
        </div>
        <div className="register-page-body__leftHalf__register-container">
          <input ref={usernameElementRef} className="register-page-body__leftHalf__register-container__input-email" type={"text"} placeholder={"User Name"}></input>
          <input ref={userEmailElementRef} className="register-page-body__leftHalf__register-container__input-password" type={"email"} placeholder={"User Email Id"}></input>
          <input ref={passwordElementRef} className="register-page-body__leftHalf__register-container__input-email" type={"password"} placeholder={"Password"}></input>
          <input ref={confirmPasswordElementRef} className="register-page-body__leftHalf__register-container__input-password" type={"password"} placeholder={"Confirm Password"}></input>
          <select ref={designationNewElementRef} className="register-page-body__leftHalf__register-container__input-email" type={"text"} placeholder={"User Designation "} onChange={(e)=>{handelOther(e)}}>
            {designation_arr.map((value, i) => {
            return (<option key={i + value} value={value}>{value}</option>)
          })}
            <option value={"Others"}>Others</option></select>
            {designationVal === "Others" ?  <input
          ref={designationElementRef}
          className="register-page-body__leftHalf__register-container__input-password"
          type={"text"}
          placeholder={"Enter designation"}
        ></input>: null}
          {/* <div className="login-page-body__leftHalf__login-container__forgotPassword">Forgot password?</div> */}
          <div onClick={handleRegisterBtnClick}
            className="register-page-body__leftHalf__register-container__btn-SignIn">Sign Up</div>
          <div
            className="register-page-body__leftHalf__register-container__register-msg1">
            Already have an account?
            <span
              className="register-page-body__leftHalf__register-container__register-msg2"
              onClick={() => { navigate("/") }}
            >Login
            </span>
          </div>
          <div ref={errorMessageElementRef}
            className="register-page-body__leftHalf__register-container__error-msg"
          >message</div>
        </div>
      </div>
      <div className="register-page-body__rightHalf">
        <img className="register-page-body__rightHalf__img"
          src="drone_in_ancient_cave_processed.png" alt="img"></img>
      </div>


    </div>
  )
}



export default Register;