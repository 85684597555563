
import React, { useEffect, useRef, useState } from "react";
import "./AddMissionFile.scss";
import axios from "axios";
import { backEndIP } from "../MyConfiguration";
import ForwardAndReturnMission from "../ForwardandRetuenMission/ForwardAndReturnMissionFile";

function AddMissionFile({
  setDetailsPopUp,
  adminDesignation,
  locationDetails,
  userCookie,
  SetLoadingVisibility,
  handleNotifyAlert }) {
  const modalRef = useRef();
  const forwardMissionfileInputElement = useRef();
  const forwardMissionfileNameTextBox = useRef();
  const returnMissionfileInputElement = useRef();
  const returnMissionfileNameTextBox = useRef();
  const forwardMissionfileExportAnchorRef = useRef()
  const returnMissionfileExportAnchorRef = useRef()
  const [previousForwardMissionFile, setPreviousForwardMissionFile] = useState("")
  const [previousReturnMissionFile, setPreviousReturnMissionFile] = useState("")
  const [nodesData, setNodesData] = useState([])
  const [hubData, setHubData] = useState([])
  const [forwardMissionFile, setForwardMissionFile] = useState("")
  const [returnissionFile, setReturnMissionFile] = useState("")
  const roadDistanceElementRef = useRef();
  const roadTimeElementRef = useRef();
  // const [roadDetails, setRoadDetails] = useState({
  //   id: locationDetails._id,
  //   roadDistance: null,
  //   roadTime: null,
  // })
  const closeModal = e => {
    if (modalRef.current === e.target) {
      setDetailsPopUp();
    }
  };
  // useEffect(() => {
  //   axios({
  //     url: backEndIP + `/hub/getSpecificHub/${locationDetails.relatedHubs}`,
  //     // url: backEndIP + "/user/login",
  //     method: "GET",
  //     headers: {
  //       authorization: userCookie.token
  //     },
  //   }).then((hubAvailable) => {
  //     setHubData(hubAvailable.data.data);
  //   })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  // }, [])

  useEffect(() => {
    const getAllData = async () => {
      const hubAvailable = await axios({
        url: backEndIP + `/hub/getSpecificHub/${locationDetails.relatedHubs}`,
        // url: backEndIP + "/user/login",
        method: "GET",
        headers: {
          authorization: userCookie.token
        },
      }).catch(async (err) => {
        console.log(err);
      })
      //console.log("hubAvailable =", hubAvailable)
      await setHubData(hubAvailable.data.data);
      //console.log("hubData =", hubData)
      //console.log("locationDetails =", locationDetails)
      const previousFrwdission = await axios({
        url: backEndIP + `/missionFiles/get_PreviousMissionFile`,
        method: "GET",
        headers: {
          authorization: userCookie.token,
          tag_name: `${hubAvailable.data.data.tag_name} to ${locationDetails.tag_name}`
        },
      }).catch((err) => {
        console.log(err);
      })
      //console.log(previousFrwdission)
      setPreviousForwardMissionFile(previousFrwdission.data.data)
      const previousRtrnMission = await axios({
        url: backEndIP + `/missionFiles/get_PreviousMissionFile`,
        method: "GET",
        headers: {
          authorization: userCookie.token,
          tag_name: `${locationDetails.tag_name} to ${hubAvailable.data.data.tag_name}`
        },
      }).catch((err) => {
        console.log(err);
      })
      //console.log(previousRtrnMission)
      setPreviousReturnMissionFile(previousRtrnMission.data.data)
    }
    getAllData()
  }, [])

  const handelSubmit = async () => {
    SetLoadingVisibility(true);
    const roadDetailsData = {
      id: locationDetails._id,
      roadDistance: roadDistanceElementRef.current.value,
      roadTime: roadTimeElementRef.current.value
    }
    console.log("roadDetailsData", roadDetailsData)
    await axios({
      url: backEndIP + `/location/update_roadData`,
      method: "PUT",
      headers: {
        authorization: userCookie.token,
      },
      data: roadDetailsData
    }).then((resp)=>{
      console.log("locationUpdate", resp.data)
      setDetailsPopUp(false);
      SetLoadingVisibility(false)
      // setAllNode(resp.data.data.sort((a, b) => (Number(a.tag_name.slice(1)) > Number(b.tag_name.slice(1)) ? 1 : -1)))
      // setDuplicateNode(resp.data.data.sort((a, b) => (Number(a.tag_name.slice(1)) > Number(b.tag_name.slice(1)) ? 1 : -1)))
      // dispatch(setHubNodeLocationsLoading("idle"))
      handleNotifyAlert(
        "notify",
        `${resp.data.message}`,
        "success",
        () => { }
      );
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const locations = {
    "63932c7ab1cab628335ae969": "Jengjal",
    "63930f82865ec3abed90bc1b": "Mendipathar PHC",
    "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
    "639311e2865ec3abed90bc23": "Nongalbibra PHC",
    "6393126c865ec3abed90bc27": "Shallang PHC",
    "639312ff865ec3abed90bc2b": "Samanda PHC",
    "63bd5b3ccafee0c35772f8f1": "Office",
    "639c35f98fa712051b926a36": "India",
    "639c5fe56c0ca3060e173c0c": "North Eastern Zone",
    "639c60e36c0ca3060e173c24": "Meghalaya",
  }
  const list = {
    "country_id": "Location",
    "zone_id": "Location",
    "state_id": "Location",
    "relatedHubs": "Location",
    "__v": "Password",
    "roadDistance": "Present",
    "roadTime": "Present",
  }
  const CompileContent = (data) => {
    // console.log(data)
    if (list[data] === "Location") {
      return locations[locationDetails[data]]
    }
    else if (list[data] === "Password") {
      return
    }
    return locationDetails[data];
  }

  const node_Data = {
    "_id": "Drone Password",
    "tag_name": "Node Name",
    "location_name": "Location Name",
    "latitude": "Latitude",
    "longitude": "Longitude",
    "country_id": "Country",
    "state": "Drone Password",
    "zone_id": "Zone",
    "state_id": "State",
    "district": "District",
    "relatedHubs": "Related Hub",
    "address": "Address",
    "pin_code": "Pin Code",
    "roadDistance": "Road Distance",
    "roadTime": "Total time required",
    "__v": "Drone Password"
  }
  useEffect(() => {
    const node = [];
    const keys = Object.keys(locationDetails);
    keys.forEach(key => {
      node.push([node_Data[key], CompileContent(key)])
    });
    //node.pop()
    setNodesData(node)


  }, [])

  //console.log(forwardMissionFile)

  // console.log(locationDetails)
  return (
    <div onClick={closeModal} ref={modalRef} className="AddMissionPopup-modalBackground">
      <div className="AddMissionPopup-modalContainer">
        <div className="AddMissionPopup-modalContainer__title">Node Details</div>
        <div className="AddMissionPopup-modalContainer__subContainer">
          <div className="AddMissionPopup-modalContainer__subContainer__left">
            {nodesData.map((item, i) => {
              // console.log(item)
              {
                if (item[0] === "Drone Password" || item[1] === null) {
                  return null
                } else {
                  return (<div className="AddMissionPopup-modalContainer__subContainer__dataContainer" key={i + 1}>
                    <div className="AddMissionPopup-modalContainer__subContainer__dataContainer__label">{item[0]}</div>
                    <div className="AddMissionPopup-modalContainer__subContainer__dataContainer__colon">:</div>
                    <div className="AddMissionPopup-modalContainer__subContainer__dataContainer__value"> {item[1]}</div>
                  </div>)
                }
              }
            })}
          </div>
          {adminDesignation !== "System User" && adminDesignation !== "Hub Manager" ? null : <div className="AddMissionPopup-modalContainer__subContainer__right">
            {locationDetails.roadDistance === null ? <div className="AddMissionPopup-modalContainer__subContainer__right__input-row-cont">
              <div className="AddMissionPopup-modalContainer__subContainer__right__input-row-cont__label">Road Distance (KM)</div>
              <div> : </div>
              <input ref={roadDistanceElementRef}
                className="AddMissionPopup-modalContainer__subContainer__right__input-row-cont__input"
                type="number"
              ></input>
            </div> : null}
            {locationDetails.roadTime === null ? <div className="AddMissionPopup-modalContainer__subContainer__right__input-row-cont">
              <div className="AddMissionPopup-modalContainer__subContainer__right__input-row-cont__label">Road Time (hrs)</div>
              <div> : </div>
              <input ref={roadTimeElementRef}
                className="AddMissionPopup-modalContainer__subContainer__right__input-row-cont__input"
                type="string"
              ></input>
            </div> : null}
            { locationDetails.roadDistance === null || locationDetails.roadTime === null? <div className="AddMissionPopup-modalContainer__SubmitBtn" onClick={handelSubmit}>Submit</div> : null}
            <div className="AddMissionPopup-modalContainer__subContainer__title">Mission Files</div>
            <ForwardAndReturnMission
              title={`${hubData.tag_name} to ${locationDetails.tag_name}`}
              adminDesignation={adminDesignation}
              fileInputElement={forwardMissionfileInputElement}
              fileNameTextBox={forwardMissionfileNameTextBox}
              missionFileType={"Forward Mission File"}
              missionFile={forwardMissionFile}
              setMissionFile={setForwardMissionFile}
              fileExportAnchorRef={forwardMissionfileExportAnchorRef}
              previousMissionFile={previousForwardMissionFile}
              SetLoadingVisibility={SetLoadingVisibility}
              handleNotifyAlert={handleNotifyAlert}
              userCookie={userCookie}
              setDetailsPopUp={setDetailsPopUp} />
            <ForwardAndReturnMission
              title={`${locationDetails.tag_name} to ${hubData.tag_name}`}
              adminDesignation={adminDesignation}
              fileInputElement={returnMissionfileInputElement}
              fileNameTextBox={returnMissionfileNameTextBox}
              missionFileType={"Return Mission File"}
              missionFile={returnissionFile}
              setMissionFile={setReturnMissionFile}
              fileExportAnchorRef={returnMissionfileExportAnchorRef}
              previousMissionFile={previousReturnMissionFile}
              SetLoadingVisibility={SetLoadingVisibility}
              handleNotifyAlert={handleNotifyAlert}
              userCookie={userCookie}
              setDetailsPopUp={setDetailsPopUp} />
          </div>}
        </div>
        <div className="AddMissionPopup-modalContainer__OkayBtn" onClick={() => { modalRef.current.click() }}>OK</div>
      </div>
    </div>
  );
}

export default AddMissionFile;
