import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import FlightDetailsPopup from '../FlightDetailsPopup/FlightDetailsPopup';
import "./AllFlightsTabContent.scss"
import ChecklistInputForm from '../ChecklistInputForm/ChecklistInputForm';
import CustomAlertBox from '../CustomAlert/CustomAlertBox';
import CustomPopUpTemplete from '../CustomPopUpTemplete/CustomPopUpTemplete';
import { useSelector } from 'react-redux';
import { selectHubNodeLocations } from '../../ReduxStore/HubNodeLocationsSlice';
import FlightFilterPopUp from '../FlightFilterPopUp/FlightFilterPopUp';
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';
import PaginationComponent from '../../Pagination/Pagination.tsx';


const AllFlightsTabContent = ({ socket,
    isConnected,
    filterBy,
    setFilterBy,
    duration,
    setDuration,
    userCookie,
    sourceLocation,
    setSourceLocation,
    adminLocation,
    handleNotifyAlert,
    SetLoadingVisibility }) => {

    const [allFlights, setAllFlights] = useState([]);
    const [flightDetailsVisibility, setFlightDetailsVisibility] = useState(false);
    const [flightDetails, setFlightDetails] = useState({});
    const [checkListDetailsPopup, setCheckListDetailsPopup] = useState(false)
    const [CheckListDetails, setCheckListDetails] = useState([])
    const [pageTitle, setPageTitle] = useState()
    const [searchValue, setSearchValue] = useState("")
    const locations = useSelector(selectHubNodeLocations)
    const [filterPopup, setFilterPopup] = useState(false)
    const [nodeLocation, setNodeLocation] = useState([])
    const [page, setCurrentPage] = useState(1)
    const [itemPerPage, setItemPerPage] = useState(20)
    const [totalItems, setTotalItems] = useState(0)
    const [valueToFilter, setValueToFilter] = useState({
        filter: filterBy,
        duration: duration,
        sourceLocation: sourceLocation
    })
    const [searchedFlights, setSearchedFlights] = useState([])
    const [timeOutId, setTimeOutId] = useState(null)
    const DurationOptions = {
        "Today": dayjs().subtract(1, "day").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 3 days": dayjs().subtract(3, "day").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 1 week": dayjs().subtract(1, "week").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 2 weeks": dayjs().subtract(2, "week").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 1 Month": dayjs().subtract(1, "month").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 3 Months": dayjs().subtract(3, "month").set('hour', 0).set('minute', 0).set('second', 0),
    }
    const flightType = [
        "ALL",
        "Order Flight",
        "Test Flight",
        "Forward Flight",
        "Return Flight"
    ]

    useEffect(() => {
        // console.log("isConnected", isConnected)
            // console.log("filterBy Params", filterBy  )
            // console.log("DurationOptions[duration.d]", DurationOptions[duration.d])
            // console.log("duration.d", duration.d )
            // console.log("valueToFilter", DurationOptions[valueToFilter.duration])
            // socket.emit('client:get_all_flights', { filterBy: filterBy, duration: DurationOptions[duration.d] })
            // console.log("allFlights AllFlight Page", allFlights)
            SetLoadingVisibility(true)
            const filterParameter = {
                filterBy: valueToFilter.filter,
                duration: DurationOptions[valueToFilter.duration.d],
                sourceLocation: valueToFilter.sourceLocation,
                page: page,
                itemPerPage: itemPerPage
            }
            // console.log("filterParameter", filterParameter)
            axios({
                url: backEndIP + "/flight/get_all_flights",
                method: "POST",
                headers: {
                    authorization: userCookie.token,
                },
                data: { ...filterParameter }
            }).then((resp) => {
                // console.log("resp", resp.data)
                SetLoadingVisibility(false)
                setTotalItems(resp.data.length)
                setAllFlights(resp.data.data)
                setSearchedFlights(resp.data.data)
            }).catch((err) => {
                console.log(err)
                SetLoadingVisibility(false);
                // handleNotifyAlert(
                //     "notify",
                //     `${err.response.data.message}`,
                //     "failed",
                //     () => { }
                // );
            })
        // axios({
        //     url: backEndIP + "/hub/hub-node-all-locations",
        //     // url: backEndIP + "/user/login",
        //     method: "GET",
        //     headers: {
        //         authorization: userCookie.token
        //     },
        // }).then((hubResp) => {
        //     // console.log(hubResp.data)
        //     setNodeLocation(hubResp.data.data);
        //     //console.log(allHub)
        // }).catch((err) => {
        //     console.log(err);
        // })

    }, [flightDetails, page])

    useEffect(() => {
        axios({
            url: backEndIP + "/hub/hub-node-all-locations",
            // url: backEndIP + "/user/login",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then((hubResp) => {
            // console.log(hubResp.data)
            setNodeLocation(hubResp.data.data);
            //console.log(allHub)
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    // const handelSearchFlight = (searchVal) => {
    //     SetLoadingVisibility(true);
    //     const filterParameter = {
    //         filterBy: valueToFilter.filter,
    //         duration: DurationOptions[duration.d],
    //         sourceLocation: valueToFilter.sourceLocation,
    //         page: page,
    //         itemPerPage: itemPerPage
    //     }
    //     console.log("searchValue", searchVal)
    //     if(searchVal === ""){
    //         handleFilterChange()
    //     } else {
    //         axios({
    //             url: backEndIP + `/flight/searched_flight/${searchVal}`,
    //             method: "GET",
    //             headers: {
    //                 authorization: userCookie.token,
    //             },
    //             data: { ...filterParameter }
    //         }).then((resp) => {
    //             console.log("resp", resp.data)
    //             // setTotalItems(resp.data.length)
    //             setAllFlights(resp.data.data)
    //             setSearchedFlights(resp.data.data)
    //             SetLoadingVisibility(false);
    //         }).catch((err) => {
    //             console.log(err)
    //             SetLoadingVisibility(false);
    //             handleNotifyAlert(
    //                 "notify",
    //                 `${err.response.data.message}`,
    //                 "failed",
    //                 () => { }
    //             );
    //         })
    //     }
    // }


    // const debounceHandler = (e)=>{
    //     if(timeOutId !== null){
    //         clearTimeout(timeOutId)
    //     }
    //     const timeOut = setTimeout(()=>{
    //         handelSearchFlight(e.target.value)
    //         setTimeOutId(null)
    //     }, 3000)
    //     setTimeOutId(timeOut)
    // }

    useEffect(() => {
        if (searchValue === "") {
            handleFilterChange()
            // setTotalItems(filteredDroneArray.length)
        } else {
            const searchId = new RegExp(`.*${searchValue}.*`, 'i')
            const filteredDroneArray = allFlights.filter((item) => {
                return searchId.test(item.drone_id)
            })
            // console.log("filteredDroneArray", filteredDroneArray)
            setTotalItems(filteredDroneArray.length)
            setSearchedFlights(filteredDroneArray)
        }
    }, [searchValue])

    // console.log("AllFlights", allFlights)
    // console.log("searechedFlights", searchedFlights)


    const handleFilterChange = () => {
        // console.log("valueToFilter", valueToFilter)
        // console.log("duration", DurationOptions[valueToFilter.duration.d])
        SetLoadingVisibility(true);
        const filterParameter = {
            filterBy: valueToFilter.filter,
            duration: DurationOptions[valueToFilter.duration.d],
            sourceLocation: valueToFilter.sourceLocation,
            page: page,
            itemPerPage: itemPerPage
        }
        // console.log("filterParameter", filterParameter)
        axios({
            url: backEndIP + "/flight/get_all_flights",
            method: "POST",
            headers: {
                authorization: userCookie.token,
            },
            data: { ...filterParameter }
        }).then((resp) => {
            console.log("resp", resp.data)
            setTotalItems(resp.data.length)
            setAllFlights(resp.data.data)
            setSearchedFlights(resp.data.data)
            SetLoadingVisibility(false);
        }).catch((err) => {
            console.log(err)
            SetLoadingVisibility(false);
            handleNotifyAlert(
                "notify",
                `${err.response.data.message}`,
                "failed",
                () => { }
            );
        })
    }

    const handelPageChange = (page) => {
        setCurrentPage(page)
    }

    console.log("valueToFilter", valueToFilter, duration)

    return (
        <div className='AllFlightsTabContent-container'>

            <div className='AllFlightsTabContent-container__filter'>
                <div className='AllFlightsTabContent-serach'>
                    <div className='AllFlightsTabContent-serach__box'>
                        <input className='AllFlightsTabContent-serach__box__input'
                            placeholder='Enter Drone Id'
                            onChange={(e) => { setSearchValue(e.target.value) }}
                        />
                        <img src={"search.svg"} alt={"search"} />
                    </div>
                </div>
                <div className='AllFlightsTabContent-serach__dispalyParams'>
                    <div className='AllFlightsTabContent-serach__dispalyParams__title'>Flight Type:</div>
                    <select className='AllFlightsTabContent-serach__dispalyParams__select'
                        defaultValue={valueToFilter.filter}
                        onChange={(e) => {
                            valueToFilter.filter = e.currentTarget.value
                            setValueToFilter({ ...valueToFilter, ["filter"]: valueToFilter.filter })
                            setFilterBy(valueToFilter.filter)
                        }}
                    >
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>{valueToFilter.filter}</option>
                        {flightType.map((type, i) => {
                            if (type === valueToFilter.filter) {
                                return
                            }
                            return <option value={type} className='AllFlightsTabContent-serach__dispalyParams__select__option'>{type}</option>
                        })}
                    </select>
                </div >
                <div className='AllFlightsTabContent-serach__dispalyParams'>
                    <div className='AllFlightsTabContent-serach__dispalyParams__title'>Duration:</div>
                    <select className='AllFlightsTabContent-serach__dispalyParams__select'
                        defaultValue={valueToFilter.duration.d}
                        onChange={(e) => {
                            valueToFilter.duration.d = e.currentTarget.value
                            duration.d = e.currentTarget.value
                            setValueToFilter({ ...valueToFilter, ["duration"]: valueToFilter.duration })
                            setDuration(duration)
                        }}
                    >
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>Today</option>
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>Last 3 days</option>
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>Last 1 week</option>
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>Last 2 weeks</option>
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>Last 1 Month</option>
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>Last 3 Months</option>
                    </select>
                </div>
                <div className='AllFlightsTabContent-serach__dispalyParams'>
                    <div className='AllFlightsTabContent-serach__dispalyParams__title'>Source Location:</div>
                    <select className='AllFlightsTabContent-serach__dispalyParams__select'
                        defaultValue={valueToFilter.sourceLocation === "ALL" || valueToFilter.sourceLocation === adminLocation ? "ALL" : locations[valueToFilter.sourceLocation].name}
                        // value={"All"}
                        onChange={(e) => {
                            // console.log("E.target.value", e.currentTarget.value)
                            valueToFilter.sourceLocation = e.currentTarget.value
                            setValueToFilter({ ...valueToFilter, ["sourceLocation"]: valueToFilter.sourceLocation })
                            setSourceLocation(e.currentTarget.value)
                        }}
                    >
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>{valueToFilter.sourceLocation === "ALL" || valueToFilter.sourceLocation === adminLocation ? "ALL" : locations[valueToFilter.sourceLocation].name}</option>
                        {nodeLocation.map((item, i) => {
                            // console.log("item", item)
                            return <option className='AllFlightsTabContent-serach__dispalyParams__select__option' value={item._id} key={i + 1}>{item.name}</option>
                        })}
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option' value="ALL" key={0}>ALL</option>
                    </select>
                </div>
                <div className='AllFlightsTabContent-serach__filterBox'>
                    <div className='AllFlightsTabContent-serach__filterBox__button' onClick={handleFilterChange}>Apply</div>
                </div>
                {/* <div className='AllFlightsTabContent-serach__filterBox'>
                    <div className='AllFlightsTabContent-serach__filterBox__button' onClick={() => setFilterPopup(true)}>Filter</div>
                </div> */}
                {/* <div className='AllFlightsTabContent-container__filter__title'>Filter by : </div>
                <div className='AllFlightsTabContent-container__filter__options'>
                    <button className={filterBy === "ALL" ? 'AllFlightsTabContent-container__filter__options__selected-item' : 'AllFlightsTabContent-container__filter__options__item'}
                        onClick={handleFilterChange}>ALL</button>
                    <button className={filterBy === "Order Flight" ? 'AllFlightsTabContent-container__filter__options__selected-item' : 'AllFlightsTabContent-container__filter__options__item'}
                        onClick={handleFilterChange}>Order Flight</button>
                    <button className={filterBy === "Return Flight" ? 'AllFlightsTabContent-container__filter__options__selected-item' : 'AllFlightsTabContent-container__filter__options__item'}
                        onClick={handleFilterChange}>Return Flight</button>
                    <button className={filterBy === "Test Flight" ? 'AllFlightsTabContent-container__filter__options__selected-item' : 'AllFlightsTabContent-container__filter__options__item'}
                        onClick={handleFilterChange}>Test Flight</button>
                </div> */}
                {/* <div className='AllFlightsTabContent-container__filter__duration-title'>Duration :</div>
                <select className='AllFlightsTabContent-container__filter__duration-select'
                    defaultValue={duration.d}
                    onChange={(e) => {
                        duration.d = e.currentTarget.value
                        socket.emit('client:get_all_flights', { filterBy: filterBy, duration: DurationOptions[duration.d] })
                    }}>
                    <option className='AllFlightsTabContent-container__filter__duration-select__option'>Last 2 weeks</option>
                    <option className='AllFlightsTabContent-container__filter__duration-select__option'>Today</option>
                    <option className='AllFlightsTabContent-container__filter__duration-select__option'>Last 3 days</option>
                    <option className='AllFlightsTabContent-container__filter__duration-select__option'>Last 1 week</option>
                    <option className='AllFlightsTabContent-container__filter__duration-select__option'>Last 1 Month</option>
                    <option className='AllFlightsTabContent-container__filter__duration-select__option'>Last 3 Months</option>
                </select> */}
            </div>
            <div className='AllFlightsTabContent-datacontainer'>
                <div className='AllFlightsTabContent-container__index'>
                    <div className='AllFlightsTabContent-container__index__flight-type AllFlightsTabContent-container__index__term'>
                        Flight Type
                    </div>
                    <div className='AllFlightsTabContent-container__index__drone AllFlightsTabContent-container__index__term'>
                        Drone Id
                    </div>
                    <div className='AllFlightsTabContent-container__index__source AllFlightsTabContent-container__index__term'>
                        Source
                    </div>
                    <div className='AllFlightsTabContent-container__index__destination AllFlightsTabContent-container__index__term'>
                        Destination
                    </div>
                    <div className='AllFlightsTabContent-container__index__payload AllFlightsTabContent-container__index__term'>
                        Payload
                    </div>
                    <div className='AllFlightsTabContent-container__index__distance AllFlightsTabContent-container__index__term'>
                        Distance
                    </div>
                    <div className='AllFlightsTabContent-container__index__flight-date AllFlightsTabContent-container__index__term'>
                        Flight Date
                    </div>
                    <div className='AllFlightsTabContent-container__index__start-time AllFlightsTabContent-container__index__term'>
                        Start Time
                    </div>
                    <div className='AllFlightsTabContent-container__index__time-taken AllFlightsTabContent-container__index__term'>
                        Time Taken
                    </div>
                    <div className='AllFlightsTabContent-container__index__order-details AllFlightsTabContent-container__index__term'>
                        Flight details
                    </div>

                </div>
                {searchedFlights.length <= 0 ? "No Flight Found" : <>
                    {searchedFlights.map((flight) => {
                        return <div key={flight._id} className='AllFlightsTabContent-datacontainer__row'>
                            <div className='AllFlightsTabContent-datacontainer__row__flight-type AllFlightsTabContent-datacontainer__row__term'>
                                {flight.flight_type}
                            </div>
                            <div className='AllFlightsTabContent-datacontainer__row__drone AllFlightsTabContent-datacontainer__row__term'>
                                {flight.drone_id}
                            </div>
                            <div className='AllFlightsTabContent-datacontainer__row__source AllFlightsTabContent-datacontainer__row__term'>
                                {locations[flight.start_location]?.name}
                            </div>
                            <div className='AllFlightsTabContent-datacontainer__row__destination AllFlightsTabContent-datacontainer__row__term'>
                                {locations[flight.end_location]?.name}
                            </div>
                            <div className='AllFlightsTabContent-datacontainer__row__payload AllFlightsTabContent-datacontainer__row__term'>
                                {flight.payload}g
                            </div>
                            <div className='AllFlightsTabContent-datacontainer__row__distance AllFlightsTabContent-datacontainer__row__term'>
                                {(flight.distance_coverved / 1000).toFixed(2)}Km
                            </div>
                            <div className='AllFlightsTabContent-datacontainer__row__flight-date AllFlightsTabContent-datacontainer__row__term'>
                                {dayjs(flight.date_created).format("YYYY-MM-DD HH:mm").toString()}
                            </div>
                            <div className='AllFlightsTabContent-datacontainer__row__start-time AllFlightsTabContent-datacontainer__row__term'>
                                {dayjs(flight.start_time).format("YYYY-MM-DD HH:mm").toString()}
                            </div>
                            <div className='AllFlightsTabContent-datacontainer__row__time-taken AllFlightsTabContent-datacontainer__row__term'>
                                {dayjs().set('hour', 0).set('minute', 0).set('second', 0).add(flight.time_taken, 'second').format('HH:mm:ss')}
                            </div>
                            <div className='AllFlightsTabContent-datacontainer__row__order-details AllFlightsTabContent-datacontainer__row__term'>
                                <button className='AllFlightsTabContent-datacontainer__row__order-details__btn'
                                    onClick={() => { setFlightDetails(flight); setFlightDetailsVisibility(true) }}
                                >Flight details</button>
                            </div>

                        </div>
                    })}</>
                }
            </div>
            <div className='AllFlightsTabContent-container__pagination'>
                <PaginationComponent
                    totalItems={totalItems}
                    itemsPerPage={itemPerPage}
                    onPageChange={handelPageChange}
                    currentPage={page}
                /></div>
            {flightDetailsVisibility && <FlightDetailsPopup
                setOpenModal={setFlightDetailsVisibility}
                setFlightDetails={setFlightDetails}
                userCookie={userCookie}
                flight={flightDetails}
                setCheckListDetailsPopup={setCheckListDetailsPopup}
                setCheckListDetails={setCheckListDetails}
                setPageTitle={setPageTitle}
                handleNotifyAlert={handleNotifyAlert}
                SetLoadingVisibility={SetLoadingVisibility} />}
            {checkListDetailsPopup && <CustomPopUpTemplete
                setOpenModal={setCheckListDetailsPopup} pageTitle={pageTitle} content={<ChecklistInputForm socket={socket}
                    checklistItems={CheckListDetails}
                    url={"client:preFlightUpdate"}
                    setIsChanged={() => { }}
                    checklistUpdateObj={{}}
                    isEditable={false} />} />}
            {filterPopup && <FlightFilterPopUp
                setFormPopup={setFilterPopup}
                userCookie={userCookie}
                setValueToFilter={setValueToFilter}
                valueToFilter={valueToFilter}
                socket={socket}
                allFlights={allFlights}
                setFilterBy={setFilterBy}
                setDuration={setDuration}
                setSearchedFlights={setSearchedFlights}
                setFlightSourceLocation={setSourceLocation}
                adminLocation={adminLocation}
                locations={locations}
            />}
        </div>
    )
}

export default AllFlightsTabContent