import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import "./UserDetailsPopUp.scss";
import axios from "axios";
import { backEndIP } from "../MyConfiguration";
import { useSelector } from "react-redux";
import { selectHubNodeLocations } from "../../ReduxStore/HubNodeLocationsSlice";

function UserDetailsPopUp({ userCookie, setOpenModal, userDetails, nodesData, SetLoadingVisibility, handleNotifyAlert, setUpdateSelf }) {
    const modalRef = useRef();
    const [permission, setPermission] = useState(userDetails.permission)
    const [approvalStatus, setApprovalStatus] = useState(userDetails.isApproved ? "Approved" : "Not Approved")
    const [userLocation, setUserLocation] = useState(userDetails.delivery_location)
    const previousData = {
        permission: userDetails.permission,
        approvalStatus: userDetails.isApproved ? "Approved" : "Not Approved",
        userLocation: userDetails.delivery_location,
    }
    const userId = userDetails._id;
    // console.log(userDetails)
    const closeModal = e => {
        if (modalRef.current === e.target) {
            setOpenModal(false);
        }
    };

    // const locations = {
    //     "63932c7ab1cab628335ae969": "Jengjal",
    //     "63930f82865ec3abed90bc1b": "Mendipathar PHC",
    //     "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
    //     "639311e2865ec3abed90bc23": "Nongalbibra PHC",
    //     "6393126c865ec3abed90bc27": "Shallang PHC",
    //     "639312ff865ec3abed90bc2b": "Samanda PHC",
    // }
    const locations = useSelector(selectHubNodeLocations);
    const list = {
        "location": "Location",
        "delivery_location": "Location",
        "cart": "Array",
        "order": "Array",
        "isApproved": "status",
        "date_birth": "time"
    }
    const CompileContent = (data) => {
        // console.log(data)
        if (list[data] === "Array") {
            return userDetails[data].length;
        }
        else if (list[data] === "Location") {
            if (userDetails[data] === "") {
                return "N/A"
            } else {
                return locations[userDetails[data]].name;
            }
        }
        else if (list[data] === "time") {
            return dayjs(userDetails[data]).format("DD-MM-YYYY");
        }
        else if (list[data] === "status") {
            if (userDetails[data] === true) {
                return "Approved";
            } else {
                return "Not Approved";
            }
        }
        else if (userDetails[data] === "" || userDetails[data] === null) {
            return "N/A"
        }
        return userDetails[data];
    }

    const [localUser, setLocalUser] = useState([])
    const users_Data = {
        "useremail": "User Email",
        "userName": "User Name",
        "cart": "Cart Length",
        "order": "No of orders",
        "location": "Connected Hub",
        "permission": "Permission",
        "verification": "Verification Status",
        "phone_no": "Phone No",
        "gender": "Gender",
        "date_birth": "Date Of Birth",
        "delivery_location": "Node Location",
        "isApproved": "Approval Status",
    }
    useEffect(() => {
        const ownFlight = [];
        const keys = Object.keys(userDetails);
        keys.forEach(key => {
            if (users_Data[key] !== undefined)
                ownFlight.push([users_Data[key], CompileContent(key)])
        });
        // console.log("ownFlight", ownFlight)
        setLocalUser(ownFlight)


    }, [userDetails])

    const handelUserPermissionsUpdate = () => {

        if (previousData.permission === permission && previousData.approvalStatus === approvalStatus && previousData.userLocation === userLocation) {
            setOpenModal(false);
        } else {
            SetLoadingVisibility(true)
            let ApprovalStatus;
            if (approvalStatus === "Approved") {
                ApprovalStatus = true
            } else {
                ApprovalStatus = false
            }
            axios({
                url: backEndIP + "/admin/update_userProfile",
                method: "PUT",
                headers: {
                    authorization: userCookie.token,
                },
                data: {
                    userId: userId,
                    permission: permission,
                    isApproved: ApprovalStatus,
                    delivery_location: userLocation,
                }
            }).then((resp) => {
                //console.log(resp.data.data)
                SetLoadingVisibility(false);
                setOpenModal(false)
                handleNotifyAlert(
                    "notify",
                    `${resp.data.message}`,
                    "success",
                    () => { }
                );
                setUpdateSelf(prev => !prev)
            }).catch((err) => {
                // console.log(err)
                SetLoadingVisibility(false);
                //  setOpenModal(false)
                handleNotifyAlert(
                    "alert",
                    `${err.response.data.message}`,
                    "failed",
                    () => { setOpenModal(false) }
                );
            })
        }
    }

    // console.log(permission)
    return (
        <div onClick={closeModal} ref={modalRef} className="UserDetailsPopup-modalBackground">
            <div className="UserDetailsPopup-modalContainer" >
                <div className="UserDetailsPopup-modalContainer__heading">User Detail</div>
                <div className="UserDetailsPopup-modalContainer__Conatiner">
                    <div className="UserDetailsPopup-modalContainer__Conatiner__left">
                        {localUser.map((item) => {
                            return <div className="UserDetailsPopup-modalContainer__item">
                                <div className="UserDetailsPopup-modalContainer__item__title">{item[0]}</div>
                                :
                                <div className="UserDetailsPopup-modalContainer__item__value"> {item[1]}</div>
                            </div>
                        })}
                    </div>
                    <div className="UserDetailsPopup-modalContainer__Conatiner__right">
                        <div className="UserDetailsPopup-modalContainer__Update">
                            <div className="UserDetailsPopup-modalContainer__Update__title">Permission</div>
                            :
                            <div className="UserDetailsPopup-modalContainer__Update__options-container">
                                <input type="radio" name="permission" id="userPermissionGrantedRadio" value={"Granted"} checked={permission === "Granted" ? true : false} onClick={(e) => { setPermission(e.target.value) }} />
                                <label className="UserDetailsPopup-modalContainer__Update__options-container__inputLabel" for="userPermissionGrantedRadio">Granted</label>
                                <input type="radio" name="permission" id="userPermissionNotGrantedRadio" value={"Not Granted"} checked={permission === "Not Granted" ? true : false} onClick={(e) => { setPermission(e.target.value) }} />
                                <label className="UserDetailsPopup-modalContainer__Update__options-container__inputLabel" for="userPermissionNotGrantedRadio">Not Granted</label>
                            </div>
                        </div>
                        <div className="UserDetailsPopup-modalContainer__Update">
                            <div className="UserDetailsPopup-modalContainer__Update__title">Approval</div>
                            :
                            <div className="UserDetailsPopup-modalContainer__Update__options-container">
                                <input type="radio" name="approval" id="userApprovedRadio" value={"Approved"} checked={approvalStatus === "Approved" ? true : false} onClick={(e) => { setApprovalStatus(e.target.value) }} />
                                <label className="UserDetailsPopup-modalContainer__Update__options-container__inputLabel" for="userApprovedRadio">Approved</label>
                                <input type="radio" name="approval" id="userNotApprovedRadio" value={"Not Approved"} checked={approvalStatus === "Not Approved" ? true : false} onClick={(e) => { setApprovalStatus(e.target.value) }} />
                                <label className="UserDetailsPopup-modalContainer__Update__options-container__inputLabel" for="userNotApprovedRadio">Not Approved</label>
                            </div>
                        </div>
                        <div className="UserDetailsPopup-modalContainer__Update">
                            <div className="UserDetailsPopup-modalContainer__Update__title">Node Location</div>
                            :
                            <div className="UserDetailsPopup-modalContainer__Update__value">
                                <select className="UserDetailsPopup-modalContainer__Update__value__select"
                                    onClick={(e) => {
                                        setUserLocation(e.target.value)
                                    }} >
                                    {userLocation === "" ? <option className="UserDetailsPopup-modalContainer__Update__value__select__option" value=""></option>
                                        : <>
                                            {nodesData.map((node) => {
                                                if (userLocation === node._id) {
                                                    return <option className="UserDetailsPopup-modalContainer__Update__value__select__option" value={userLocation}>{node.location_name}</option>
                                                }

                                            })}</>}
                                    {nodesData.map((node) => {
                                        if (userLocation === node._id) {
                                            return null
                                        } else {
                                            return <option className="UserDetailsPopup-modalContainer__Update__value__select__option" value={node._id}>{node.location_name}</option>
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="UserDetailsPopup-modalContainer__OkayBtn" onClick={handelUserPermissionsUpdate}>OK</div>
            </div>
        </div>
    );
}

export default UserDetailsPopUp;
