import React, { useRef, useState } from 'react'
import "./NewItemChecklistForm.scss"
import { backEndIP } from './MyConfiguration';
import axios from 'axios';


const InputFieldBox = ({ item, style }) => {
    const InputRef = useRef()
    const handleInputChange = (e) => {
        // console.log(e.target.value)
        item.setValue(prev => e.target.value)
    };

    return (
        <div className="NewItemChecklistForm__input-row-cont" style={style ? style.parent : null}>
            <div className="NewItemChecklistForm__input-row-cont__label" style={style ? style.label : null}
            >{item.label}</div>
            <div> : </div>
            <input ref={InputRef}
                className="NewItemChecklistForm__input-row-cont__input" style={style ? style.input : null}
                type={item.type}
                value={item.value}
                onChange={handleInputChange}
            ></input>
        </div>
    );
};


const NewItemChecklistForm = ({ setOpenModal, checklistItemAdd, SetLoadingVisibility, setUpdateSelf }) => {
    const modalRef = useRef();

    const [checklistItemLabel, setChecklistItemLabel] = useState("")
    const [parameters, setParameters] = useState([])
    const [btnColor, setBtnColor] = useState(["#FD6125", "#FD6125"]);
    const [inputTypeSelect, setInputTypeSelect] = useState("text")
    const [inputSelectOptions, setInputSelectOptions] = useState([])
    const [optionName, setOptionName] = useState("")
    const [inputParaLabel, setInputParaLabel] = useState("")
    const closeModal = e => {
        if (modalRef.current === e.target) {
            setOpenModal(false);
        }
    };

    const moveParameterUp = (index) => {
        const para1 = parameters[index];
        parameters[index] = parameters[index - 1]
        parameters[index - 1] = para1
        setParameters([...parameters])
    }
    const moveParameterDown = (index) => {
        const para1 = parameters[index];
        parameters[index] = parameters[index + 1]
        parameters[index + 1] = para1
        setParameters([...parameters])
    }

    const handleCreateItemClick = () => {
        if (checklistItemLabel !== "") {
            const itemData = {
                label: checklistItemLabel,
                parameters: parameters,
                active: true,
                notes: "",
            }
            SetLoadingVisibility(true);
            axios({
                url: backEndIP + checklistItemAdd.url,
                method: "POST",
                headers: {
                    authorization: checklistItemAdd.userToken,
                },
                data: {
                    itemData: itemData,
                    templateTitle: checklistItemAdd.templateTitle
                }
            })
                .then((resp) => {
                    console.log(resp.data.data);
                    setUpdateSelf(prev => !prev)
                    SetLoadingVisibility(false);
                    setOpenModal(false)
                })
                .catch((err) => {
                    SetLoadingVisibility(false);
                    if (err.response.data.message) window.alert(err.response.data.message);
                    else window.alert("some error occurered");
                    console.log(err);
                });
        } else {
            window.alert("checklist Item label can not be empty")
        }
    }
    return (
        <div onClick={closeModal} ref={modalRef} className="NewItemChecklistForm-modalalertBackground">
            <div className="NewItemChecklistForm-modalalertContainer" >
                <div className='NewItemChecklistForm__pageTitle'>Add New Checklist Item</div>
                <InputFieldBox item={{ label: "Checklist Item Label", type: "text", value: checklistItemLabel, setValue: setChecklistItemLabel }}
                    style={{ parent: { width: "90%" }, label: { width: "15%" }, input: { width: "85%" } }}>

                </InputFieldBox>
                <div className='NewItemChecklistForm'>
                    <div className='NewItemChecklistForm__parameters-box'>
                        <div className='NewItemChecklistForm__parameters-box__left'>
                            <div className='NewItemChecklistForm__parameters-box__left__title'>Parameter List for Checklist Item</div>
                            <table className='NewItemChecklistForm__parameters-list'>
                                <tr className='NewItemChecklistForm__parameters-list__index'>
                                    <th className='NewItemChecklistForm__parameters-list__index__SrNo'>S.No</th>
                                    <th className='NewItemChecklistForm__parameters-list__index__name'>Parameter Name</th>
                                    <th className='NewItemChecklistForm__parameters-list__index__type'>Type</th>
                                    <th className='NewItemChecklistForm__parameters-list__index__options'>Options</th>
                                    <th className='NewItemChecklistForm__parameters-list__index__actions'>Actions</th>
                                </tr>
                                {parameters.map((item, i) => {
                                    return <tr className='NewItemChecklistForm__parameters-list__row'>
                                        <td className='NewItemChecklistForm__parameters-list__row__SrNo'>{i + 1}</td>
                                        <td className='NewItemChecklistForm__parameters-list__row__name'>{item.paraName}</td>
                                        <td className='NewItemChecklistForm__parameters-list__row__type'>{item.paraType}</td>
                                        <td className='NewItemChecklistForm__parameters-list__row__options'>
                                            {item.paraOptions.length ? item.paraOptions.map((opName) => {
                                                return <div>{opName}</div>
                                            }) : <div>-</div>}
                                        </td>
                                        <td className='NewItemChecklistForm__parameters-list__row__actions'>
                                            <img className='MissionPlanPointRow-container__row1__updownimg'
                                                src="./upArrowIcon.png" alt="UP"
                                                onClick={() => {
                                                    if (i !== 0) { moveParameterUp(i) }
                                                }} />
                                            <img className='MissionPlanPointRow-container__row1__updownimg'
                                                src="./downArrowIcon.png" alt="DN"
                                                onClick={() => {
                                                    if (i < parameters.length - 1)
                                                        moveParameterDown(i)
                                                }} />
                                            <img className='MissionPlanPointRow-container__row1__updownimg'
                                                src="./deleteIcon.png" alt="DEL"
                                                onClick={() => {
                                                    const params = [...parameters.slice(0, i), ...parameters.slice(i + 1)];
                                                    setParameters(params)
                                                }} />
                                            {/* <button className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowAction__btn'
                                            onClick={() => {
                                                if (i !== 0) { moveParameterUp(i) }
                                            }}>UP</button>
                                        <button className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowAction__btn'
                                            onClick={() => {
                                                if (i < parameters.length - 1)
                                                    moveParameterDown(i)
                                            }}>DN</button>
                                        <button className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowAction__btn'
                                            onClick={() => {
                                                const params = [...parameters.slice(0, i), ...parameters.slice(i + 1)];
                                                setParameters(params)
                                            }}>Delete</button> */}
                                        </td>
                                    </tr>
                                })}
                            </table>
                        </div>
                        <div className='NewItemChecklistForm__parameters-box__right'>
                            <div className='NewItemChecklistForm__parameters-cont'>

                                <div className='NewItemChecklistForm__parameters-cont__addrow'>
                                    <InputFieldBox item={{ label: "Parameter Label", type: "text", value: inputParaLabel, setValue: setInputParaLabel }}></InputFieldBox>

                                    <div className="NewItemChecklistForm__input-row-cont">
                                        <div className="NewItemChecklistForm__input-row-cont__label"
                                        >Input Type</div>
                                        <div> : </div>
                                        <select className='NewItemChecklistForm__input-row-cont__input'
                                            value={inputTypeSelect}
                                            defaultValue={inputTypeSelect}
                                            onChange={(e) => {
                                                setInputTypeSelect(e.target.value)
                                                // console.log(e.target.value)
                                            }}>
                                            <option value={"text"}>Text Field</option>
                                            <option value={"number"}>Number</option>
                                            <option value={"DropDown"}>DropDown</option>
                                        </select>
                                    </div>
                                    {inputTypeSelect === "DropDown" && <div className='NewItemChecklistForm__parameters-cont__options-cont'>
                                        <div className='NewItemChecklistForm__parameters-cont__options-cont__title'>
                                            dropdown options
                                        </div>
                                        <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont'>
                                            <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__index'>
                                                <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowSrNo'>S.No</div>
                                                <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowOptionName'>Option Name</div>
                                                <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowAction'>
                                                    Actions
                                                </div>
                                            </div>
                                            {inputSelectOptions.map((item, i) => {
                                                return <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__row'>
                                                    <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowSrNo'>{i + 1}</div>
                                                    <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowOptionName'>{item}</div>
                                                    <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowAction'>
                                                        <button className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowAction__btn'
                                                            onClick={() => {
                                                                const options = [...inputSelectOptions.slice(0, i), ...inputSelectOptions.slice(i + 1)];
                                                                setInputSelectOptions(options)
                                                            }}>Delete</button>
                                                    </div>
                                                </div>
                                            })}
                                            <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__row'>
                                                <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowSrNo'>-</div>
                                                <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowOptionName'>-</div>
                                                <div className='NewItemChecklistForm__parameters-cont__options-cont__allOptions-cont__rowAction'>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='NewItemChecklistForm__parameters-cont__options-cont__addNewRow'>
                                            <InputFieldBox item={{
                                                label: "Add Options", type: "text", value: optionName,
                                                setValue: setOptionName
                                            }} ></InputFieldBox>
                                            <button onClick={() => {

                                                if (optionName !== "" && inputSelectOptions.includes(optionName) === false) {

                                                    const options = [...inputSelectOptions]
                                                    options.push(optionName)
                                                    setOptionName("")
                                                    setInputSelectOptions(options)
                                                }
                                                else {
                                                    window.alert("DropDown options name can not be empty or same, please add a name for dropdown option")
                                                }
                                            }}>Add Option</button>
                                        </div>

                                    </div>}
                                    <button className='NewItemChecklistForm__parameters-cont__addrow__addparaBtn'
                                        onClick={() => {
                                            const isPresent = parameters.every((par) => {
                                                // console.log(par.paraName,inputParaLabel,par!==inputParaLabel)
                                                return par.paraName !== inputParaLabel
                                            })
                                            if (inputParaLabel !== "" && isPresent) {
                                                if (inputTypeSelect === "DropDown") {
                                                    if (inputSelectOptions.length) {
                                                        const params = [...parameters]
                                                        params.push({ paraName: inputParaLabel, paraType: inputTypeSelect, paraOptions: inputSelectOptions,value:"Select" })
                                                        setInputParaLabel("")
                                                        setInputTypeSelect("text")
                                                        setInputSelectOptions([])

                                                        setParameters(params)
                                                    }
                                                    else {
                                                        window.alert("dropdown options can not be empty as input type seleted is dropdown")
                                                    }
                                                }
                                                else {
                                                    const params = [...parameters]
                                                    params.push({ paraName: inputParaLabel, paraType: inputTypeSelect, paraOptions: inputSelectOptions,value:"" })
                                                    setInputParaLabel("")
                                                    setInputTypeSelect("text")
                                                    setInputSelectOptions([])

                                                    setParameters(params)
                                                }
                                            }
                                            else if (!isPresent) {
                                                window.alert(`parameter with name "${inputParaLabel}" is already present`)
                                            }
                                            else {
                                                window.alert("please provide parameter name")
                                            }
                                        }}
                                    >Add Parameter</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className='NewItemChecklistForm__createItemBtn' onClick={handleCreateItemClick}>Create Item</button>
                <button className='NewItemChecklistForm__closeBtn' onClick={() => { setOpenModal(false) }}>X</button>
            </div>
        </div >

    );
}

export default NewItemChecklistForm
